@import 'src/sass/ve.scss';


.tanker-rates {
  &__section {
    display: flex;
  }

  .dropdown-with-label {
    position: relative;
    .dropdown {
      display: block;
    }
  }

  &__flat-rate-fields, &__gvr-fields{
    width: 200px;
  }
  &__worldscale-fields{
    width: 150px;
  }
  &__ls-fields{
    width: 120px;
  }
  .dropdown{
    height: 30px;
    width: 120px;
    padding-top: 8px;
    padding-right: 10px;
  }

  &__section-field {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    label {
      font-size: 12px;
    }
  }
}
