@import 'src/sass/ve.scss';

.menu {
  position: relative;
  z-index: 100;

  &-content {
    position: absolute;
    right: $guttering-small;
    border: 1px solid $maritech-white;
    background: $maritech-background-black;
    min-width: 150px;
    max-width: 400px;
    max-height: 900px;
    overflow-y: auto;
  }

  &-option-list {
    max-height: 400px;
    overflow: auto;
    @include styled-scrollbar(8px);
}

  &-item {

    &-draggable {
      display: flex;
      align-items: center;
      padding: $guttering-tiny $guttering-tiny;
      cursor: pointer;

      &:hover {
        color: $font-color-primary;
        background: $maritech-blue-dark;
        visibility: visible;

        .menu-item-drag-icon {
          visibility: visible;
        }
      }
    }

    &-drag-icon {
      visibility: hidden;
    }

    &-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 200px;
      max-width: 200px;
    }
  }

  &-open {
    color: $maritech-action;
  }
}

.draggable-visible {
  visibility: visible;
  background: $maritech-blue-dark;
  color: $font-color-primary;
}
