@import 'src/sass/ve.scss';
@import './../styles.scss';

.warning-box {
  @extend %notice-box;

  background-color: $maritech-caution;

  .secondary-button {
    &:not([disabled]):hover,
    &:not([disabled]):active {
      background-color: $maritech-base-darker;
    }
  }
}
