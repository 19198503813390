.padding-top-micro {
  padding-top: $guttering-micro;
}

.padding-top-tiny {
  padding-top: $guttering-tiny;
}

.padding-top-small {
  padding-top: $guttering-small;
}

.padding-top-small-medium {
  padding-top: $guttering-small-medium;
}

.padding-top-medium {
  padding-top: $guttering-medium;
}

.padding-top-large {
  padding-top: $guttering-large;
}

.padding-right-small {
  padding-right: $guttering-small;
}

.padding-bottom-micro {
  padding-bottom: $guttering-micro;
}

.padding-bottom-tiny {
  padding-bottom: $guttering-tiny;
}

.padding-bottom-small {
  padding-bottom: $guttering-small;
}

.padding-bottom-small-medium {
  padding-bottom: $guttering-small-medium;
}

.padding-bottom-medium {
  padding-bottom: $guttering-medium;
}
