@import 'src/sass/ve.scss';

.worksheet-tab {
  cursor: pointer;
  color: $maritech-white;
  width: 146px;
  padding: 0 $guttering-small-medium;

  &__wrapper {
    display: flex;
    text-decoration: none;
    outline: none;
    height: 34px;
  }

  &__content {
    overflow: hidden;
    outline: none;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 auto;
  }

  &--active {
    color: $maritech-action;
  }

  &:hover,
  &:focus {
    color: $maritech-action;
  }

  &--errored {
    display: flex;
    align-items: center;
  }

  &__warning-icon {
    color: $maritech-warning;
    margin-right: $guttering-small;

    &::before {
      font-size: $guttering-medium !important;
    }
  }

  &__grip-icon {
    color: $maritech-gold;
    background-color: $maritech-background-black;
  }
}
