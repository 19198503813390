@import 'src/sass/ve.scss';
$border-separation: 1px solid $maritech-white;

.summary-voyage-data {
  display: table;
  border-collapse: collapse;
  width: 100%;
  border-bottom: $border-separation;
  &__header {
    display: table-row;
    border-bottom: 1px solid $maritech-white;

    &-cell {
      display: table-cell;
      padding: 0 $guttering-tiny $guttering-small $guttering-tiny;

      &--right {
        text-align: right;
      }
    }
  }

  &__row {
    display: table-row;

    &-cell {
      display: table-cell;
      padding: $guttering-small $guttering-tiny;

      &--right {
        text-align: right;
      }
    }

    &:nth-child(even) {
      background-color: $maritech-background-blue;
    }
  }

  &__footer {
    display: table-row;
    border-top: 1px solid $maritech-white;

    &-cell {
      display: table-cell;
      padding: $guttering-small $guttering-tiny;

      &--highlight {
        color: $maritech-gold;
      }

      &--right {
        text-align: right;
      }
    }
  }
}
