@import 'src/sass/ve.scss';

.marker-label {
  color: white;
  border-radius: 12px;
  padding: $guttering-tiny $guttering-small-medium;
  background: $maritech-action;
  font-size: $font-size-body-1;
  opacity: 0.79;
  position: absolute;
  z-index: -5;
  top: 10px;
  left: 14px;
  white-space: nowrap;
}
