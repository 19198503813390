@import 'src/sass/ve.scss';

.speed-and-cons-multi-vessel {
  display: block;
  $table-dark-color: $maritech-background-black;

  fieldset.speed-and-cons-multi-vessel-group {
    border: 0;
    &:disabled {
      opacity: 0.6;
      pointer-events: none; /* unfortunately downshift doesn't adhere to the fieldset disabled so added this to prevent mouse events */
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
  }

  &__top-divider {
    margin-top: $guttering-medium + $guttering-tiny;
    margin-bottom: $guttering-small;
  }

  &__scrubber-fuel-consumption {
    .dropdown {
      display: inline-block;
      width: 9em; /*Fix the width, so that the content around it doesn't jitter as the user changes the value (especially when aligned right there's at least this dropdown's own label that jumps about). */
    }
  }

  &__user-controls {
    display: flex;
    margin-bottom: $guttering-small;
  }

  &__subheader-section {
    display: flex;    
    justify-content: space-between;
    height: 30px;
  }

  &__subheader {
    display: flex;
    align-items: flex-start;
  }

 &__save-button-section {
    display: flex;
    align-items: flex-end;
    margin-top:-1px !important;
 }

  .table-header {
    background: $table-dark-color;
    height: $grid-row-height;
    display: flex;
    flex-direction: row;
    align-items: center;

    > span {
      padding: $guttering-small $guttering-tiny;
      white-space: normal;
    }
  }

  .table-header > *,
  .table-row > * {
    flex-basis: 0;
    flex-grow: 1;
  }

  .unused-row {
    opacity: 0.5;
  }

  &__moving,
  &__static {
    .table-header > * {
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }
  }

  .table-row {
    height: $grid-row-height;
    display: flex;
    align-items: center;

    &:nth-child(odd) {
      background: $table-dark-color;
    }
  }

  .table-row {
    border-style: solid;
    border-color: $table-dark-color;
    // Use `border-box` to draw the border inside the div, so we don't have to account for its thickness
    box-sizing: border-box;
    border-left-width: 2px;
    border-bottom-width: 2px;
    border-top-width: 0;
    border-right-width: 0;
  }

  %type {
    padding-left: $guttering-tiny;

    .dropdown-label {
      display: block;
    }

    .dropdown__display {
      padding-left: 0;
    }
  }

  %info {
    max-width: 25px;
    height: 25px;
  }

  %speedNcon {
    max-width: 70px;
  }

  %generator {
    max-width: 60px;
    margin-left: $guttering-tiny;
  }

  .status-specific-fuel-grade {
    min-width: 100px;
  }

  &__moving {
    width: 53%;

    &--eco * {
      color: $maritech-positive;
    }

    &--type {
      @extend %type;
      min-width: 100px;
      max-width: 100px;
    }

    &--info {
      @extend %info;
    }

    &--speed,
    &--consumption {
      @extend %speedNcon;
    }

    &--generator {
      @extend %generator;
    }
  }

  &__static {
    width: 47%;

    &--type {
      @extend %type;
      min-width: 85px;
      max-width: 85px;
    }

    &--info {
      @extend %info;
    }

    &--consumption {
      @extend %speedNcon;
    }

    &--generator {
      @extend %generator;
    }
  }

  &__table--location-type-specific:not(:first-child) {
    $guttering-between-tables: $guttering-medium;
    .table-header > :first-child {
      margin-left: $guttering-between-tables;
    }

    .table-row {
      margin-left: $guttering-between-tables;
    }
  }

  &__refresh-btn {
    padding: 0;
    width: 25px;
  }
}

.save-banner-header-text {
  padding-top: 5px;
}
