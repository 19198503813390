@import 'src/sass/ve.scss';

.max-lift-tooltip {
  width: 170px;
  word-wrap: break-word;
  text-align: left;
}

.draft-dropdown {
  position: relative;

  &-label {
    display: inline-block;
  }

  & &__display {
    height: 20px;
    background: $maritech-background-black;
    color: $font-color-primary;
    text-align: left;
    border: 1px solid $maritech-white;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    outline: none;

    &:focus {
      border-color: $maritech-action;
    }

    &--icon {
      font-style: normal;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &-label &__display {
    height: 20px;
    background: none;
    color: $font-color-primary;
    text-align: left;
    border: none;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    outline: none;

    &:focus {
      color: $maritech-action;
      border-color: $maritech-action;
    }

    &--icon {
      color: $font-color-primary;
      font-style: normal;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &,
  &-label {
    & &__menu {
      border-radius: 0;
      border: 1px solid $maritech-action;
      position: absolute;
      z-index: 999;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &-item {
        color: $font-color-primary;
        background: $maritech-background-black;
        padding: 8px 10px;
        display: inline-flex;
        white-space: nowrap;

        &--highlighted {
          background: $maritech-blue-dark;
        }
      }
    }
  }

  & &__menu {
    min-width: 100%;
  }
}

.voyage-title {
  margin-bottom: 0;
}

.voyage-icon {
  color: $maritech-gold;
  transform: translateY(-5%);
  margin-left: -5px;

  &__size {
    $desiredSize: 20px;
    $originalSize: 24px;
    $scaleFactor: $desiredSize / $originalSize;
    transform: scale($scaleFactor);
    line-height: 1;
  }
}

.port-autocomplete-tankers-location {
  width: 160px;
}

.voyage-title-container {
  display: flex;
  margin-bottom: 40px;

  &--divider {
    opacity: 0.5;
    margin: 0 $guttering-medium;
    height: 20px;
  }

  &--controls {
    display: flex;
    align-items: flex-start;
    margin-left: auto;
  }
}

.warning-box {
  .warning-box__header {
    font-size: 15px;
  }

  .warning-box__text {
    padding-top: 5px;
    padding-right: 10px;
    margin-left: 8px;
    margin-right: 16px;
  }

  .button--secondary {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 0;
    margin-right: 0;

    &:hover {
      color: darken(white, 15%);
      background-color: $maritech-base-darker;
    }
  }
}

.voyage-data {
  // prevent voyage add and remove buttons, within the voyage table header, from being truncated when the browser is not maxmised
  min-width: 1250px;

  %row-common {
    display: flex;
    align-items: center;
  }

  &__header {
    @extend %row-common;
    height: 40px;
    background: $maritech-background-black;
    border-bottom: 1px solid $maritech-white;

    &-cell {
      flex: 1 1;
    }

    &-cell.voyage-data--action {
      margin-right: -10px;
    }

    &-cell-no-padding {
      flex: 1 1;
      padding: 0;
    }
  }

  &__row {
    @extend %row-common;

    height: $grid-row-height - 2;


    &-cell {
      flex: 1;

      &__grip-icon {
        color: $maritech-gold;
      }
    }

    &__drag {
      border: 1px solid transparent;
      outline: none;

      &:focus {
        border-color: $maritech-action;
      }
    }
  }

  &__eea_row {
    max-width: 35px;
    padding-left: 5px;
  }

  &--eea {
    max-width: 35px;
  }

  &--grip {
    min-width: 24px;
    max-width: 24px;
  }

  &--type {
    min-width: 45px; 
    max-width: 45px;
  }

  &--location {
    max-width: 94px;
  }

  &--gear {
    min-width: 64px;
  }

  &--distance {
    min-width: 60px;
  }

  &--seca-distance {
    min-width: 60px;
  }

  &--distances {
    min-width: 132px;
    cursor: auto;

    .calc-input {
      font-size: 12px;
      display: flex;
      align-items: center;
      padding: 0 0 0 4px;
    }

    .voyage-caution-icon {
      margin-right: 4px;
    }
  }

  &--draft {
    min-width: 50px;
    max-width: 70px;
  }

  &--salinity {
    min-width: 60px;
  }

  &--quantity {
    min-width: 78px;
  }

  &--max-lift {
    min-width: 68px;
  }

  &--max-lift-cell {
    min-width: 68px;
    height: 30px;
  }

  &--max-lift-cell-num {
    text-align: right;
    padding: 8px 5px 6px 0;
  }

  &--max-lift-cell-warning {
    text-align: right;
    padding: 4px 2px 0 0;
  }

  &--max-lift-cell-unused {
    opacity: 0.5;
  }

  &--max-lift-value-cell-unused {
    background: $maritech-background-black;
    border: 1px solid $maritech-white;
    opacity: 0.5;
  }

  &--max-lift-cell-border {
    border: 1px solid $maritech-white;
    background: $maritech-background-black;
  }

  &--max-lift-cell-border-bottom {
    border: 1px solid $maritech-white;
    border-bottom: 1px solid white;
  }

  &--max-lift-value {
    color: $maritech-gold;

    .voyage-caution-icon {
      margin-right: 2px;
    }
  }

  &--load-rate {
    min-width: 60px;
  }

  &--load-rate-unit {
    min-width: 70px;
  }

  &--working-day {
    min-width: 117px;
  }

  &--delay {
    // Summary of --delay-value and --delay-unit
    min-width: 88px;

    // See #AlignmentIssueInVoyage - This exists as a hack for now, until a permanent fix is worked upon
    &-without-TT {
      min-width: 130px;
    }
  }

  &--delay-value {
    min-width: 32px;
  }

  &--delay-unit {
    min-width: 44px;
  }

  &--turn-time {
    // Summary of --turn-time-value and --turn-time-unit
    min-width: 88px;
  }

  &--turn-time-value {
    min-width: 32px;
  }

  &--turn-time-unit {
    min-width: 44px;
  }

  &--weather {
    min-width: 32px;
  }

  &--cost {
    min-width: 80px;
  }

  &--action {
    min-width: 32px;
    padding-left: 2px;

    // See #AlignmentIssueInVoyage - This exists as a hack for now, until a permanent fix is worked upon
    &-without-TT {
      padding-left: 0;
      
      .add-voyage-leg-button {
        padding-left: 3px;
      }
    }

    &-delete {
      font-size: 22px;
      padding-left: 3px;

      &:hover {
        color: $maritech-warn;
      }
    }
  }

  & .port-cost-dropdown {
    right: 0;
  }

  & .port-draft-dropdown {
    left: -377px;
  }
}

.add-voyage-leg-button {
  font-size: 22px;
}

.new-voyage-table {

  &__header {
    background: $maritech-background-blue;
    border-bottom: none;
    margin-left: 24px;
  }
}

.voyage-deviation-tooltip {
  button {
    padding-left: 0;
    margin-right: $guttering-medium;
  }

  &__body {
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: $guttering-tiny;
    }

    &--names {
      color: #fff;
    }
  }
}

.voyage-caution-icon {
  color: $maritech-caution;
  margin-right: $guttering-small;

  &::before {
    font-size: $font-size-subheader;
  }
}

.voyage-inline-warning {
  border: 1px solid $maritech-caution;
  padding: $guttering-small $guttering-medium;
  display: flex;
  align-items: center;
  margin-top: $guttering-medium;

  &__icon,
  &__message {
    margin-right: $guttering-small;
  }

  &__message {
    strong {
      color: #fff;
    }
  }

  &__update-voyage-button {
    margin: 0 0 0 auto;
  }
}

.cell-placeholder {
  height: 100%;
  background-color: #091624 !important;
  opacity: 0.5;
}
