@import 'src/sass/ve.scss';

$right-panel-min-width: 620px;

%stage-panel {
  display: flex;
  flex-direction: column;
}

.stage {
  color: $maritech-white;
  display: flex;
  width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  @include styled-scrollbar(10px);

  max-height: 100%;
  min-height: 100%;

  &__left {
    @extend %stage-panel;
    position: relative;
    min-width: 1280px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-right: 16px;
    background: $maritech-background-blue;

    .panel {
      height: auto;
      margin-bottom: $guttering-massive;
    }
  }

  &__right {
    @extend %stage-panel;
    position: relative;
    min-width: $right-panel-min-width;
    max-width: $right-panel-min-width; /** fixes an issue with comparison causing this panel to expand beyond the provided width */
    background: $maritech-background-black;
    max-height: calc(100vh - #{$sea-header-height-unpinned});
    min-height: calc(100vh - #{$sea-header-height-unpinned});

    @at-root body.pinned--open & {
      min-height: calc(100vh - #{$sea-header-height-pinned});
      max-height: calc(100vh - #{$sea-header-height-pinned});
    }

    .notices-list {
      /**
       * A decision has been made to make this notices list show on top of the the app-wide notices list.
       * This does indeed introduce issues to end-users when app-wide notices and right stage notices are visible
       * at the same time. 
       */
      z-index: $in-app-modal-dialog-z-index + 2;
      width: 100%;
    }
  }
  .tankers-right-panel {
    min-width: 400px;
    max-width: 400px;
  }
}

.ve-tooltip-default {
  background: $maritech-background-black !important;
  color: $maritech-white !important;
  border: 1px solid $maritech-white !important;
  z-index: 9999;
  opacity: 1 !important;
}

.__react_component_tooltip:before,
.__react_component_tooltip:after {
  content: none !important;
}

.__react_component_tooltip {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.__react_component_tooltip.show {
  /* Found that when deployed to dev for testing, the opacity was still 0.9, however when testing
locally with a dev build it would render with opacity 1 */
  opacity: 1 !important;
}
