@import 'src/sass/ve.scss';

.voyage-cost-section {
  width: 350px;
  margin: $guttering-medium $guttering-medium 0 $guttering-medium;
}

.voyage-cost-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.voyage-cost-label-text {
  color: #FFFFFFE5;
  font-size: $font-size-subheader;
  font-weight: 600;
  border-bottom: 0;
}

.voyage-cost-data {
  display: table;
  width: 100%;
  margin-left: 10px;
}

