@import 'src/sass/ve.scss';

$eua-years-border-radius: 5px;
$eua-years-background-color: #363636;
$eua-years-fill-color: #AA7A39;
$second-header-color: #858688;
$cells-border-color: $maritech-white;
$cells-border-lite: #a4a4a440;

.eua-price {
  width: 80px;
}

.refresh-btn {
  width: 25px;
}

.carbon-breakdown-price {
  display: flex;
  font-size: 14px;
  line-height: 20px;
}

.eua-price-property {
  line-height: 14px;
}

.eua-price-label {
  display: flex;
}

.iconTooltip-eua {
  display: flex;
  $desiredSize: 16px;
  $originalSize: 24px;
  $scaleFactor: $desiredSize / $originalSize;
  transform: scale($scaleFactor)  translateY(-40%);
  line-height: 1;
  color: #FFFFFF;
  &-text {
    max-width: 325px;
    word-wrap: break-word;
    text-align: left;
    }
}

.carbon-breakdown-header {
  display: flex;
  flex-direction: row;

  &--container {
    display: flex;
    flex-direction: row;
    margin-bottom: $guttering-medium;
  }

  &--divider {
    opacity: 0.5;
    margin: 0 $guttering-medium;
    height: 20px;
  }

  &-right {
    justify-content: flex-end;
    display: flex;
    flex: 3;
    padding-right: 2px;
  }

  &--title {
    color: $maritech-gold;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }

  &--carbon-factors-link {
    display: inline-flex;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline dotted;
    text-underline-position: under;
  }

  &--open-popup-icon {
    height: 18px;
    width: 20px;
    margin-left: $guttering-small;
  }

  &--eua-years-container {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
  }

  &--eua-years-selector {
    display: flex;
    flex-direction: row;
    margin-top: $guttering-small-medium;
  }

  &--eua-year {
    background-color: $eua-years-background-color;
    border: 1px solid $eua-years-fill-color;
    border-left: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding: 2px 20px 0 20px;

    &[data-is-selected=true] {
      background-color: $eua-years-fill-color;
      color: white;
    }

    &:first-child {
      border-left: 1px solid $eua-years-fill-color;
      border-radius: $eua-years-border-radius 0 0 $eua-years-border-radius;
    }

    &:last-child {
      border-radius: 0 $eua-years-border-radius $eua-years-border-radius 0;
    }
  }
}

.carbon-breakdown-title {
  margin-bottom: 0;
}

.eua-description {
  margin-top: $guttering-medium;
}

.carbon-breakdown {
  display: grid;
  margin-bottom: 4px;
  margin-top: 24px;

  grid-template-columns: 45px 105px 105px 105px 110px 110px;
  div {
    padding: 8px 12px;
    text-align: right;
  }

  &--first-header {
    border-bottom: 1px solid $cells-border-color;
    color: $maritech-gold;
    font-size: 14px;
    text-align: center !important;
  }

  &--first-header-summaryPanel {
    border-bottom: 1px solid $cells-border-color;
    font-size: 13px;
    text-align: center !important;
    sub {
      font-size: 13px;
    }
  }

  .header-lite {
    border-bottom: 1px solid $cells-border-lite;
  }

  &--second-header {
    color: #858688;
  }

  &--row-even {
    background-color: #09162480;
  }

  &--row-even-summary-panel {
    background-color: $maritech-background-blue;
  }

  &--right-border {
    border-right: 1px solid $cells-border-color;
  }

  .right-border-lite {
    border-right: 1px solid $cells-border-lite;
  }

  &--footer {
    border-top: 1px solid $cells-border-color;
    font-weight: 600;
  }

  .footer-lite {
    border-top: 1px solid $cells-border-lite;
  }

  &--footer-summaryPanel {
    border-top: 1px solid $cells-border-color;
    color: $maritech-gold;
  }

  &--rowspan-2 {
    grid-column: span 2;
  }

  &--rowspan-3 {
    grid-column: span 3;
  }

  &--total {
    color: white;
  }
  &--total-summaryPanel {
    color: $maritech-gold;
  }
}
