@import 'src/sass/ve.scss';

.sandc-audit-modal  {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  z-index: 99 !important;
  border-color: $maritech-white;
  background: rgba(0, 0, 0, 0.6);

  .sandc-section {
    position:fixed;
    width: 60%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background: $maritech-background-black;
    color: $maritech-white;
    border: 1px solid  $maritech-white;

    .sandc-data-container {
      margin: 0 10px 0 10px;

      .sandc-header-row {
        display: flex;
        color: $maritech-white;
        text-align: center;
        justify-content: space-between;
        padding:5px 0;
        border-bottom : 1px solid  $maritech-white;  
        font-size:small ;

        .sandc-title {
          justify-content: center;
          padding:5px 0;
          width: 90%;
          color: white;
        }

        .sandc-close{
          align-items: flex-end;
        }
      }

      .border:last-child {
           border-bottom: 0 solid !important;
      }

      .sandc-scrollable  {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 170px;
        flex-direction: row;
        @include styled-scrollbar(8px);
        @include show-scrollbar-only-on-hover();
      }

      .sandc-no-data-container {
        display: flex;
        color: $maritech-white;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 80px;
      }

      .sandc-row-container {
        display: flex;
        flex-direction: row;
        color: $maritech-white;
        padding: 3px 0;
        border-bottom: 1px solid $maritech-white;

        .sandc-user-img {
          display: flex;
          flex-direction: column;
          color: $maritech-white;
          margin-right: 15px;
          padding-top: 4px;
          padding-bottom: 4px;
        }

        .sandc-data-row-main {
          display: flex;
          flex-direction: column;
          color: $maritech-white;
          padding: 3px 0;
          flex: 1;
          overflow: hidden;

          .sandc-data-row {
            display: flex;
            flex-direction: row;
            color: $maritech-white;
            padding: 3px 0;
            justify-content: flex-start;

            .highlighted {
              font-size: 1em;
              font-weight: 600;
            }

            .normal {
              font-size: 0.85em;
            }

            .sandc-data-col {
              text-align: left;
              min-width: 120px;              
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}

.user-img {
  display: flex;
  margin: auto 10px auto 0;
  width: 48px;
  height: 48px;
  background-color: white;
}
