@import 'src/sass/ve.scss';

.tab {
  background: $maritech-background-black;
  border-style: solid none solid;
  border-top: $guttering-tiny transparent solid;
  border-bottom: $guttering-tiny transparent solid;
  outline: none;
  position: relative;

  &:focus {
    outline: none;
  }

  &-anchor {
    display: block;
    position: absolute;
    top: 0;
    height: 1px;
    width: 0;

    &--left {
      left: 0;
    }
    &--right {
      right: 0;
    }
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    right: 20px;
    top: 0;
    height: 100%;
  }

  &-anchor {
    position: absolute;
    display: block;
    right: 0;
    width: 0;
    height: 100%;
    top: 0;
  }

  &:before {
    content: ' ';
    display: block;
    width: 100%;
    height: $guttering-tiny;
    background-color: $maritech-action;
    position: absolute;

    top: 100%;
    left: 0;
    width: 0;
    transition: width 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &--active {
    &:before {
      width: 100%;
    }
  }
}
