@import 'src/sass/ve.scss';


.freight-incomes {
  color: $maritech-white;
   //line-height: 8px;
  &__table {
   
    border: none;
    border-collapse: collapse;

    thead {
      tr {
        background-color: transparent;
      }
      th {
        padding: 0 0 8px 0;
        text-align: left;
        background-color: transparent;
      }

    }
    tbody {
      tr {
        td {
          padding: 0;
        }
      }
      tr {
        &:not(:first-child) {
          td {
            padding-top: 12px;
          }
        }
      }
      tr:nth-child(odd),
      tr:nth-child(even) {
        &,
        th,
        td {
          background-color: transparent;
          &:not(:first-child) {
            padding-left: 8px;
          }
        }
      }
    }
    td {
      background-color: transparent;
      border-spacing: 0;
      border: none !important;
    }
  
  }

.freight-income-input{
  line-height: 13px;

  .unit-label {
    top: 52%;
  }
}
  &-input {
    line-height: 13px;
  }

  &-add {
    display: flex;
    align-items: center;
  }

  &-add-btn {
    padding-right: 5px;
    font-size: 22px;
  }

  &-remove-btn {
    font-size: 22px;
  }
}
