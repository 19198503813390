@import 'src/sass/ve.scss';

.modal {
  position: absolute;
  z-index: 300;
  background: $maritech-background-black;
  border: 1px solid $maritech-white;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}


