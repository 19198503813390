@import 'src/sass/ve.scss';
$border-separation: 1px solid $maritech-grey-darker;

.days-breakdown-header {
  margin-top: $guttering-medium;
  margin-bottom: $guttering-medium;
  color: $maritech-white;
}

.table-days-breakdown {
  width: 100%;

  & > td:not(:first) {
    text-align: center;
  }

  &__row {
    & > th:nth-child(1),
    th:nth-child(3),
    th:nth-child(6),
    td:nth-child(1),
    td:nth-child(3),
    td:nth-child(6),
    .border-right {
      border-right: $border-separation;
    }
    & > th:first-child,
    & > td:first-child {
      text-align: left;
    }

    & > td {
      text-align: right;
    }
  }
  &__summary-row {
    & > th:not(:last-child),
    td:not(:last-child) {
      border-right: $border-separation;
    }
    &.footer {
      color: $maritech-gold;
      & > td {
        text-align: center;
      }
    }
  }
}
