@import 'src/sass/ve.scss';

.radio-button {
  position: relative;

  &__input {
    position: absolute;
    visibility: hidden;
  }

  &__label {
    display: block;
    position: relative;
    height: 20px;
    padding: 4px 0 0 30px;
    z-index: 2;
    cursor: pointer;
  }

  &__check {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid $font-color-primary;
    border-radius: 50%;
    z-index: 1;
    transition: border 0.25s linear;

    &::before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      top: 3px;
      left: 3px;
      margin: auto;
      transition: background 0.25s linear;
    }
  }

  &:hover &__check {
    border-color: $maritech-action;
  }

  &__input:checked ~ &__check {
    border-color: $maritech-action;
  }

  &__input:checked ~ &__check::before {
    background: $maritech-action;
  }
}
