@import 'src/sass/ve.scss';

.summary-cargo-container {
  padding-left: $guttering-medium;
  border-left: 1px solid $maritech-gold;
}

.lowest-maximum-lift {
  &__value {
    display: inline-block;
  }
}
