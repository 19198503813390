@import '../../styles.scss';

$workbook-panel-team-workbooks-filter-height: 114px;
$workbook-panel-pre-team-workbooks-grid-content-height: $workbook-panel-top-content-incl-colleagues-toggle-height + $workbook-panel-team-workbooks-filter-height;

.search-panel {

  &__tool-bar {
    display: block;
    background-color: $maritech-background-black;
    padding-top: $guttering-large;
    padding-bottom: $guttering-large;
    border-bottom: 1px solid shade($maritech-white, $color-very-dark);
    border-top: 1px solid shade($maritech-white, $color-very-dark);
    margin-bottom: 10px;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;

    &-container{
      display: flex;
      justify-content: flex-end;
    }

    &-user-selection {
      display: flex;
      text-align: left;

      .label {
        width: 100%;

        span {
          text-align: left;
        }
      }
    }
    &-label{
      margin-bottom: 5px;
    }
  }

  .team-workbooks {
    text-align: left;
    margin-top: 10px;

    &__search {
      width: 100%;
    }
    &__list {
      text-align: left;

      .grid-height-determiner {
        /* ag-grid needs nominal height here - https://www.ag-grid.com/javascript-grid-width-and-height/ */
        height: calc(#{$workbook-panel-height} - #{$workbook-panel-pre-team-workbooks-grid-content-height});

        @at-root body.pinned--open #{&} {
          height: calc(#{$workbook-panel-height-on-expanded-nav-header} - #{$workbook-panel-pre-team-workbooks-grid-content-height});
        }
      }
    }
  }
}