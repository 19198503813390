@import 'src/sass/ve.scss';

$notice-title-color: #fff;
$notice-content-color: $font-color-tertiary;

%notice-box {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 48px;

  font-size: $font-size-body-1;
  padding: $guttering-small;
  color: $notice-content-color;

  .icon-only-button {
    opacity: 0.7;

    &:hover {
      color: #fff;
      opacity: 1;
    }
  }
  button,
  input[type='button'],
  .notice-box__button {
    padding-left: $guttering-medium;
    font-size: $font-size-body-1;
    text-decoration: none;
    color: white;
    margin: 0;
  }

  font-weight: $font-weight-light;

  .notice-box__type-icon {
    color: $notice-title-color;
    margin-right: $guttering-small;
  }

  .notice-box__type-icon-indicator {
    position: absolute;
    bottom: 7px;
    left: 20px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 2px solid $notice-title-color;
    text-align: center;
    background-color: $maritech-background-black;
    color: $notice-title-color;
    font-size: 11px;
    padding-top: 1px;
    font-weight: bold;
  }

  .notice-box__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $notice-title-color;
    h1 {
      /*Reset undesired styles from user-agent */
      font-weight: normal;
      margin: 0;

      /*Proper styles on how to appear */
      color: $notice-title-color;
      margin-right: $guttering-medium;
      font-size: $font-size-subheader;
      font-weight: $font-weight-regular;
    }

    .notice-box__standard-dismiss-button {
      margin-left: auto; /* Aligns to the right - see https://stackoverflow.com/questions/35269947/css-align-one-item-right-with-flexbox/35270047#35270047 */
    }
    .warning-box__text {
      margin-right: $guttering-medium;
    }
  }
}
