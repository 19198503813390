@import 'src/sass/ve.scss';

.vessel-autocomplete {
  position: relative;

  &__input {
    height: 30px;
    border: 1px solid $maritech-white;
    background: $maritech-background-black;
    color: $font-color-primary;
    text-align: left;
    padding: 0 $guttering-small;
    outline: none;

    &:focus {
      border-color: $maritech-action;
    }

    &-isMandatory {
      border-color: #F7CD46;
    }
  }

  &__menu {
    border-radius: 0;
    border: 1px solid $maritech-action;
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &-item {
      color: $font-color-primary;
      background: $maritech-background-black;
      padding: 8px 10px;
      display: inline-flex;

      &--imo {
        width: 60px;
      }

      &--name {
        width: 160px;

        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &--deadweight {
        width: 100px;
        text-align: right;
      }

      &--flag {
        width: 30px;
        text-align: center;
      }

      &--highlighted {
        background: $maritech-blue-dark;
      }
    }
    .tankers-intake {
      max-width: 360px;
    }
  }
}
