@import 'src/sass/ve.scss';

.worksheet-modal-input {
  input {
    height: 44px;
    width: 498px;
    background: #000000;
    padding: 0 $guttering-large;
    margin: 0 $guttering-massive;
    border-radius: $base-corner-radius;
    font-size: $font-size-body-2;
  }
}
