@import 'src/sass/ve.scss';

.action-bar__secondary {
  justify-content: flex-end;

  &--tab {
    color: $maritech-white;
    width: 100%;
    cursor: pointer;

    &-active {
      color: $maritech-action;
    }

    &:hover,
    &:focus {
      color: $maritech-action;
    }
  }
}
