@import 'src/sass/ve.scss';

.waypoint-label {
  position: relative;
  display: inline-block;

  .label-wrapper {
    display: block;
    visibility: hidden;
  }

  .progress-wrapper {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.waypoints {
  &-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .tankers-waypoints &__waypoint {
    margin-bottom: 0;
  }
  &__waypoint {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: $guttering-medium;
    margin-top: 10px;
    margin-bottom: 10px;

    &[data-unavoidable='true'] {
      position: relative;

      &:before {
        content: 'Unavoidable';
        position: absolute;
        font-weight: 500;
        top: 24px;
        left: 0;
        font-size: 11px;
      }
    }

    &[data-obsolete='true'] {
      position: relative;

      &:before {
        content: 'Obsolete';
        position: absolute;
        font-weight: 500;
        top: 24px;
        left: 0;
        font-size: 11px;
      }
    }

    &[data-unavoidable='true'][data-obsolete='true'] {
      position: relative;

      &:before {
        content: 'Obsolete/Unavoidable';
        position: absolute;
        font-weight: 500;
        top: -14px;
        left: 0;
        font-size: 11px;
      }
    }

    input {
      width: auto;
      margin-right: $guttering-small;
      cursor: pointer;
    }

    label {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}
