@import '@maritech/design-system/src/sass/design-system-dark.scss';
@import 'common/utilities/all.scss';

$scrollbar-track-color: #0c1b2a;
$scrollbar-background-color: #49545f;

/** In-app, meaning any multi-app containers (like the cross-app organization's navigation menu) should be above it */
$in-app-modal-dialog-z-index: 300;
$cross-app-nav-menu-z-index: 1000;
$browser-viewport-wide-modal-dialog-z-index: $cross-app-nav-menu-z-index + 1;
$action-bar-height: 40px;
$sea-header-height-unpinned: 64px;
$sea-header-height-pinned: 104px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-black: 900;
$cera-font-weight-thin: 100;
$cera-font-weight-light: 200;
$cera-font-weight-regular: 400;
$cera-font-weight-medium: 600;
$cera-font-weight-bold: 700;
$cera-font-weight-black: 800;

$base-input-height: 30px;

/**
 * Common properties of every standard calc input.
 */
.calc-input {
  @extend .calc-input-canvas;
  // a container element is needed, for why see #RequiredContainerForHoveringElements
  &__container {
    /* inline-block display, so that the container behaves like the `<input>` that it wraps. */
    display: inline-block;
    /* position: 'relative' makes this container a reference point for any descendants hovering around it (position: absolute) */
    position: relative;

    .calc-input_invalid_status_container {
      &--hidden {
        /* need to retain the size so that elements around this field don't jump around */
        visibility: hidden;
      }
      &--guidance {
        color: $maritech-orange;
      }
      &--error {
        color: $maritech-warn;
      }
      margin-top: $guttering-tiny;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 10px;
      .has-icon::before {
        font-size: 14px;
      }
    }
  }

  height: $base-input-height;
  color: $font-color-primary;
  padding: 0 $guttering-small;
  outline: none;

  &:focus {
    border-color: $maritech-action;
  }

  &--guidance,
  &--guidance:focus {
    border-color: $maritech-orange;
  }

  &--invalid,
  &--invalid:focus {
    border-color: $maritech-warn;
  }

  &__validation_messages {
    position: absolute;
    /*Need to raise the default z-index, because the messages are to hover over the inline content that follows the input. Otherwise we get overwritten due to the natural behavior of drawing content in the order they are placed in the DOM tree) */
    z-index: 1;
  }
}

.calc-input-canvas {
  border: 1px solid $maritech-white;
  background: $maritech-background-black;
}

@mixin styled-scrollbar($thickness) {
  &::-webkit-scrollbar {
    width: $thickness;
    height: $thickness;
  }

  &::-webkit-scrollbar-corner {
    background: $maritech-background-black;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollbar-track-color;
  }

  &::-webkit-scrollbar-thumb {
    // the border is the same color as the track so the bar
    // looks smaller and thus contained in track
    border: 1px solid $scrollbar-track-color;
    background-color: $scrollbar-background-color;
    width: $thickness;
    border-radius: 3px;
  }
}

@mixin show-scrollbar-only-on-hover {
  &:not(:hover) {
    // only hide the elements, not the ::-webkit-scrollbar, so that contents of the container don't move
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-corner {
      display: none;
    }
  }
}

html,
body {
  font-family: 'Roboto', 'Helvetica', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Cera', 'Roboto', 'Helvetica', sans-serif;
}

@import 'table.scss';
