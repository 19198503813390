@import 'src/sass/ve.scss';

.sensitivity {
  &__settings {
    display: flex;
    align-items: flex-end;
    padding: 0 0 $guttering-medium $guttering-medium;
  }

  &__header {
    width: 70px;
    font-size: $font-size-subheader;
    margin-bottom: 6px;
  }

  &__divider {
    margin-bottom: $guttering-medium;
  }

  &__option {
    margin: 0 $guttering-large 5px 0;
  }

  &__value {
    display: inline-flex;
    flex-direction: column;

    &--second-fuel-grade {
      margin-left: $guttering-large;
    }

    &--first-fuel-grade,
    &--second-fuel-grade {
      width: 100px;
    }
  }

  &__label {
    margin-bottom: $guttering-small;
  }

  &__input {
    width: 100px;

    &--fuel-value {
      width: 75px;
    }
  }

  &__table {
    min-height: 362px;

    &__row--highlight {
      color: $maritech-gold;
    }

    &__row--highlight > &__cell:first-child {
      color: $font-color-primary;
    }

    &__cell {
      text-align: right;
      padding: 8px 4px;
      width: 100%;
    }
  }

  &__progress-bar{
    margin-top: auto;
    margin-bottom: auto;
  }
}
