@import 'src/sass/ve.scss';

.expand-collapse-main {
  display: flex;
}

.expand-collapse-button {
  display: flex;
  align-items: center;

  background: none;
  border: none;
  color: $font-color-primary;
  padding: 0;
  cursor: pointer;
  outline: none;
  &__icon {
    /*The icon is implemented as a font character that doesn't take the full height of the line.
     Force the height to the actual visible part to prevent unnatural stretch of containers.*/
    line-height: 12px;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.expand-collapse-additional-header-section {
  display: flex;
}
