@import 'src/sass/ve.scss';

.ag-header-cell:not(:last-child) > .ag-header-cell-comp-wrapper > .workbook-header {
    border-right: 1px solid rgba($maritech-white, 0.5);
  }

.workbook-header {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 $guttering-small;

  &__sort-icon {
    &::before {
      font-size: $font-size-body-2 !important;
    }
  }
}

.workbook-card {
  &__cell {
    display: block;
    padding: $guttering-small;
    text-decoration: none;
    font-size: $font-size-caption;
    color: inherit;

    &--name {
      font-size: $font-size-body-2;
      overflow: hidden;
      text-overflow: ellipsis;

      &.editable-label,
      &.editable-label__textbox {
        width: 100%;
        padding: $guttering-small $guttering-small 0 $guttering-small;
        margin: 0;
        font-size: $font-size-body-2;
        color: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--worksheet-count,
    &--updated-date {
      padding-top: $guttering-small + 3;
    }

    &--worksheet-count {
      padding-right: $guttering-small * 4;
      text-align: right;
    }

    &--actions {
      padding-right: $guttering-tiny;
      display: flex;
      justify-content: flex-end;

      &-delete {
        &:hover {
          color: $maritech-warn;
        }
      }
    }
  }
}
