@import 'src/sass/ve.scss';

.toggle {
  display: flex;
  align-items: center;

  &__switch {
    margin-right: $guttering-small;
  }
}
