@import 'src/sass/ve.scss';

.ve-comparison-table {
  font-size: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .dropdown__display {
    width: 175px;
  }

  .comparison-cell-output {
    color: $maritech-gold;
  }

  .comparison-cell--calc {
    color: white;
  }

  .group-header--workbookname {
    .ag-header-group-text {
      overflow: hidden;
      padding-left: $guttering-tiny;
      text-overflow: ellipsis;
      color: white;
      &:before {
        content: 'Workbook ';
        color: $maritech-white;
      }
    }
  }

  /*
  TODO after demostration, it was found that the placement of the horizontal scrollbar for the grid is hidden in situations where
    the screen size in reduced (in the case of the ipad pro). This makes scrolling horizontally difficult as the user has to scroll
    down using the window's vertical scrollbar to bring the horizontal scrollbar into view.
    Some possible options to solve this:
    * move the horizontal scroll to be under the grid header, this way its
      always visible even when the screen size is reduced
    * make the horizontal scroll relatively positioned to always be above
      the parent scroll (seems hard, not too sure how to go about this)
  */
  &__grid {
    // while it's understandable to apply the flex style settings for `&__grid` we also, unfortunately,
    // need override the child div (i.e. `&__grid > div`) as this `div` is owned by aggrid and it
    // applies, sadly, a custom style to it
    &,
    & > div {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    // based on advice found [here](https://github.com/ag-grid/ag-grid/issues/2873#issuecomment-458083193)
    // this is needed so that aggrid will correct receive from it's parent `&__grid`
    .ag-root-wrapper.ag-layout-normal {
      flex-grow: 1;
      height: 0;
      min-height: 0;
    }
    // hide the pinned scroll bar as there's no overflow of pinned columns, so it's serves no purpose
    .ag-horizontal-left-spacer {
      overflow: hidden;
    }
    // make the style of the aggrid's scrollbars fit our theme
    .ag-body-viewport,
    .ag-body-vertical-scroll-viewport,
    .ag-body-horizontal-scroll-viewport {
      @include styled-scrollbar(10px);
    }

    .ag-body-vertical-scroll,
    .ag-body-vertical-scroll-viewport {
      width: 10px !important;
      min-width: 10px !important;
      max-width: 10px !important;
      max-height: 98% !important;
    }
    // found that aggrid's width of the horizontal scroll and the spacer were set to 17px, so this
    // has to be overridden otherwise our scroll track would appear much larger than the thumb
    .ag-body-horizontal-scroll {
      width: 100%;
    }
    .ag-body-horizontal-scroll,
    .ag-body-horizontal-scroll-viewport {
      height: 10px !important;
      min-height: 10px !important;
      max-height: 10px !important;
    }
    .ag-horizontal-right-spacer {
      width: 10px !important;
      min-width: 10px !important;
      max-width: 10px !important;
    }
  }

  .ag-header-group-cell-label,
  .ag-header-group-cell {
    line-height: 32px;
    justify-content: space-around;
    user-select: none;
  }

  .ag-header-group-cell {
    user-select: none;
    & + .ag-header-group-cell {
      border-left: 1px solid rgba(#ffffff, 0.3);
    }
  }

  .ag-header-cell,
  .ag-cell {
    line-height: 32px;
    justify-content: space-around;
  }

  .ag-header-cell-label {
    line-height: 32px;
    justify-content: space-between;
  }

  .ag-overlay {
    position: relative;

    .ag-overlay-no-rows-center {
      padding-top: 10px;
      padding-bottom: 10px;
      display: block;
    }
  }

  .ag-icon {
    background-position: center center;
    background-size: 80% auto;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;

    &.ag-icon-desc {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='10px' height='5px' viewBox='0 0 10 5' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='7 10 12 15 17 10'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='3.0VE-Updates_UI-' transform='translate(-941.000000, -631.000000)'%3E%3Cg id='Group-7' transform='translate(8.000000, 267.000000)'%3E%3Cg id='Group-8' transform='translate(0.000000, 293.000000)'%3E%3Cg id='Icons/_arrows/arrow-drop-down' transform='translate(926.000000, 61.000000)'%3E%3Cg id='📐-z_-Padding/_null' transform='translate(-1.000000, -1.000000)'%3E%3C/g%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Mask' fill='%23FFFFFF' xlink:href='%23path-1'%3E%3C/use%3E%3Cg id='🎨Colour' mask='url(%23mask-2)' fill='%23FFFFFF'%3E%3Crect id='Rectangle' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
    &.ag-icon-asc {
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10px' height='5px' viewBox='0 0 10 5' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpolygon id='path-1' points='12 9 7 14 16.999 14'%3e%3c/polygon%3e%3c/defs%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='37.2-Comparison_Loading' transform='translate(-348.000000, -1098.000000)'%3e%3cg id='Icons/_arrows/arrow-drop-down' transform='translate(341.000000, 1089.000000)'%3e%3cg id='📐-z_-Padding/_null' transform='translate(-1.000000, -1.000000)'%3e%3c/g%3e%3cmask id='mask-2' fill='white'%3e%3cuse xlink:href='%23path-1'%3e%3c/use%3e%3c/mask%3e%3cuse id='Mask' fill='%23FFFFFF' xlink:href='%23path-1'%3e%3c/use%3e%3cg id='🎨Colour' mask='url(%23mask-2)' fill='%23FFFFFF'%3e%3crect id='Rectangle' x='0' y='0' width='24' height='24'%3e%3c/rect%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    }
    &:before {
      display: none;
    }
  }

  .ag-header-row {
    border-top: 1px solid rgba($maritech-white, 0.3);
    user-select: none;
  }

  .group-header {
    font-weight: 500;
  }

  .comparison-default-row:hover {
    background-color: $maritech-row-highlight-light;
  }

  .group-header--workbookname {
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }

  &__user-controls {
    width: 100%;
    display: flex;
    padding: 20px;
  }

  &__user-actions {
    flex-grow: 1;
    justify-content: flex-end;
    display: flex;
    align-items: center;

    &-export {
      margin-left: $guttering-medium;
    }
  }

  &__columns-setter {
    position: relative;
    width: 124px;
  }

  &__expand-icon-section {
    display: flex;

    & .vertical-divider {
      margin-top: -$guttering-medium-large;
      margin-bottom: -$guttering-medium-large;
    }
  }

  &__view-rates--dropdown {
    display: flex;
    margin-right: $guttering-small;
    margin-top: $guttering-small;
  }

  &__speed-and-consumption-mode {
    display: flex;
    margin-left: $guttering-medium;
    align-items: flex-end;
    flex-direction: column;
    margin-top: -14px;

    &--title {
      display: flex;
    }
    &--toggle {
      margin-top: $guttering-small;
      width: 100%;
      font-size: 14px
    }
    &--dropdown {
      display: flex;
      align-items: center;

      .dropdown__display {
        border: none;
        width: 160px;
      }
      .dropdown__menu {
        border: none;
      }
    }
    &--divider-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -18px;
    }
    &--divider-section .vertical-divider {
      height: 28px;
      margin-top: 2px;
    }
    &--divider-section .icon--info-outline::before {
      font-size: 16px;
    }
    &--tooltip-text {
      width: 194px;
      font-size: 13px;
    }
  }

  &__refresh-bunkers {
    display: flex;
    align-items: center;
    margin-right: $guttering-small;
    cursor: pointer;

    &--icon-btn {
      margin-left: $guttering-tiny;
    }

    &[data-disabled='true'] {
      cursor: not-allowed;
    }
  }

  .comparison-worksheet-anchor {
    &,
    &:hover,
    &:visited,
    &:active {
      display: block;
      color: $font-color-primary;
      text-decoration: none;
    }
  }

  .comparison-row {
    background-color: rgba(0, 0, 0, 0.25), $maritech-background-blue;

    &.ag-row-even {
      // NOTE: Need to use an explicit class, rather than `:nth-child`, because Ag-Grid's 'rerender prevention' mechanisms actually put rows in different order sometimes!
      background-color: $maritech-background-blue;
    }

    &:hover {
      background-color: $maritech-row-highlight-dark;
    }

    &--active {
      background-color: $maritech-action !important;
      .comparison-cell,
      .comparison-cell-output {
        color: white;
      }

      .comparison-worksheet-anchor {
        &,
        &:hover,
        &:visited,
        &:active {
          color: $font-color-selected;
          text-decoration: none;
        }
      }

      .striped-loader {
        .stripe .stripe-inner {
          background: white;
        }
      }
    }

    &--failed {
      .comparison-cell--loading {
        padding-top: 7px;
      }
    }

    &--loading {
      .comparison-cell--loading {
        padding-top: 7px;
      }
    }
  }

  .comparison-fail-handle {
    display: flex;
    align-items: center;
    line-height: 1em;

    &__info-icon {
      width: 20px;
      margin-right: 4px;
    }

    &__text {
      flex-grow: 1;
      text-align: left;
    }

    &__refresh-icon {
      cursor: pointer;
      margin-top: 8px;
    }
  }

  .comparison-cell {
    padding-left: 4px;
    padding-right: 4px;
    line-height: 32px;
    height: 32px;

    &--trim {
      .comparison-worksheet-anchor {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }

    &--center {
      text-align: center;
    }
  }

  .comparison-header {
    padding-left: 4px;
    padding-right: 4px;
    & + .comparison-header {
      border-left: 1px solid rgba(#ffffff, 0.3);
    }

    &--left {
      text-align: left;
    }

    &--right {
      text-align: left;
    }

    &--center {
      text-align: center;
    }

    &--worksheet {
      border-left: none !important;
    }

    &--no {
      .ag-header-cell-text {
        width: 24px;
        height: 24px;
        display: inline-block;
      }
    }

    .ag-header-icon.ag-sort-ascending-icon,
    .ag-header-icon.ag-sort-descending-icon {
      position: absolute;
      right: 4px;
    }
  }

  .comparison-cell-output {
    color: #aa7a39;
  }

  .ag-tooltip {
    background: $maritech-background-blue !important;
    color: $maritech-white !important;
    border: 1px solid $maritech-action !important;
    z-index: 9999;
    padding: $guttering-small;
    border-radius: 4px;
  }

  &-expanded {
    position: fixed;
    width: 93%;
    height: 100%;
    z-index: 3;
    right: 8px;
    background-color: $maritech-background-black;

    .ag-layout-normal {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      max-height: 96%;
    }

    .ag-body-horizontal-scroll {
      position: fixed !important;
      bottom: 10px;
      width: 93%;
    }

    .ve-comparison-table__columns-setter {
      width: 344px;

      &__button {
        padding-right: 238px;
        padding-left: $guttering-medium;
      }
    }
  }
}
