@import 'src/sass/ve.scss';

$carbon-factor-grid-width: 600px;

.carbon-factor-table-modal {
  display: flex;
  flex-direction: column;
  padding: $guttering-medium;
  padding-bottom: 0;

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $guttering-small;
    width: 100%;

    &__title {
      font: 700 $guttering-large 'Roboto';
      color: $font-color-primary;
    }

    &__close-btn {
      margin-bottom: $guttering-small;
    }
  }

  &__grid {
    border: 2px solid $maritech-background-black;
    height: calc(100vh - 250px);
    width: $carbon-factor-grid-width;

    .carbon-factor-row {
      width: $carbon-factor-grid-width;
      background-color: $maritech-background-black;
    }

    .carbon-factor-row-even {
      background-color: $maritech-background-blue;
    }

    .ag-header-cell,
    .ag-cell {
      padding-left: 5px;
      line-height: 32px;
      justify-content: space-around;
    }

    .ag-body-viewport {
      @include styled-scrollbar(10px);
    }

    .ag-body-horizontal-scroll-viewport {
      display: none;
    }
  }
}
