@import 'src/sass/ve.scss';
$alternate-row-bg-color: $maritech-background-blue;
$border-headers-separation: 1px solid $maritech-white;
$border-column-groups-separation: 1px solid $maritech-grey-darker;
$default-cell-padding: 8px 4px 8px 4px;

.bunkers-itinerary-view {
  .table-outer {
    width: 100%;
    display: grid;
    grid-template-columns: 120px 1fr 120px;
    & > div:first-child {
      border-right: $border-headers-separation;
    }
    & > div:last-child {
      border-left: $border-headers-separation;
    }

    .table-inner-pinned-column {
      display: grid;

      & > div {
        padding: $default-cell-padding;
        max-height: 30px;
      }
      &.right-column {
        text-align: right;
        grid-template-rows: repeat(auto-fit, minmax(30px, 30px));
      }

      &.left-column {
        text-align: left;
        grid-template-rows: repeat(auto-fit, minmax(30px, 30px));
        font-weight: $font-weight-medium;
      }

      & > div:nth-child(2) {
        border-bottom: $border-headers-separation;
      }
      & > div:nth-last-child(2) {
        border-bottom: $border-headers-separation;
      }
      & > div:nth-child(2n + 3) {
        background: $alternate-row-bg-color;
        max-height: 30px;
      }
      & > div:nth-last-child(1) {
        height: 40px;
        max-height: 40px;
      }

      &.right-column > div:last-child {
        padding: 14px 4px 4px 4px;
      }
      &.left-column > div:last-child {
        padding: 6px 8px 8px 12px;
      }
      //TODO: Took too long to apply these correctly, had to use !important.
      //bad practice, but should be safe due to names are uniq.
      .type-heading-none-expand {
        padding: 8px 8px 8px 24px !important;
        max-height: 30px;
      }
      .type-heading-expand {
        padding: 8px 8px 8px 0 !important;
        max-height: 30px;
      }
      .type-heading-expand-child {
        padding: 8px 8px 8px 34px !important;
        max-height: 30px;
      }
    }

    .table-inner-fuel-grade {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      @include styled-scrollbar(8px);

      .fuel-grade-columns {
        border-right: 1px solid $maritech-grey-darker;
        text-align: right;
        width: 100%;

        .fuel-grade-row {
          display: grid;
          grid-template-columns: 65px 25px 85px auto;
          grid-template-rows: repeat(auto-fit, minmax(30px, 30px));

          .cell-heading-fuel-type {
            grid-column: 1 / span 4;
            text-align: center;
            font-weight: $font-weight-medium;
          }
          &.cell-heading > div,
          &.cell-ending > div {
            border-bottom: $border-headers-separation;
          }

          & > div {
            padding: $default-cell-padding;
          }
        }
        & > div:nth-child(2n + 3) {
          background: $alternate-row-bg-color;
        }
        & > div:nth-last-child(1) {
          height: 40px;
          padding: 6px 4px 4px 0;
        }
      }

      &.fuel-grade-columns:last-child {
        border-right: none;
      }
    }

    .cell-heading {
      white-space: nowrap;
      font-weight: $font-weight-medium;
      max-height: 30px;
    }

    .truncate {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .clarksons-white {
    color: $maritech-white;
    font-weight: $font-weight-medium;
  }

  .consumption-formula {
    font-weight: $font-weight-regular;
    /*Literal color is needed because Sea/calc's base color is not the bright $font-color-primary, but the darker $maritech-white, which makes the $font-color-tertiary not stand-out enough*/
    color: #707a83;
    font-size: $font-size-caption;
  }
}
