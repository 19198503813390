.carousel {
  display: flex;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0px;
    display: none;
    height: 0px;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 3px;
  }
  &:hover::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 3px;
  }

  &[data-has-scrolled='true'] {
    .reset-scroll-button {
      transform: scale(1);
    }
  }

  &__control-spacer {
    width: 24px;
  }
}

.carousel-wrapper {
  overflow: hidden;
  flex-grow: 1;
}
