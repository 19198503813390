@import 'src/sass/ve.scss';

$menu-z-index: 990;
.table-dropdown {
  position: relative;
  .calc-input__validation_messages {
    z-index: $menu-z-index + 1;
  }

  &__menu {
    border-collapse: collapse;
    border-radius: 0;
    border: 1px solid $maritech-action;
    position: absolute;
    z-index: $menu-z-index;
    min-width: 100%;
    cursor: pointer;
    background: $maritech-background-black;
    width: 960px;

    &-cell {
      padding: 8px 10px;
      white-space: normal;
      height: 100%;
      cursor: pointer;

      &--right {
        text-align: right;
      }
    }

    &-header-cell {
      background: $maritech-background-blue;
      border-bottom: 1px solid $maritech-white;
      border-right: 1px dotted $maritech-white;
      padding: 0 10px;
    }

    &-item {
      background: $maritech-background-black;
      color: $font-color-primary;
    }

    &-header-cell,
    &-cell {
      &--highlighted {
        border-right: 1px solid $maritech-action;
        background: rgba($maritech-action, 0.3);
        box-shadow: -1px 0 0 0 $maritech-action, 1px 0 0 0 $maritech-action;

      }
    }
  }
}
.ag-row.ag-row-level-0:hover,
.ag-row.ag-row-level-0.ag-row-even:hover,
.ag-row.ag-row-level-0.ag-row-odd:hover {
  background: $maritech-blue-dark;
}

.ag-body-viewport {
  width: 100%;
}

.ag-body-vertical-scroll-viewport {
  @include styled-scrollbar(8px);
}

.dropdown-grid {
  height: 100%
}

.ag-header-cell-label {
  justify-content: space-between;
}

.ag-icon {
  background-position: center center;
  background-size: 80% auto;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;

  &.ag-icon-desc {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='10px' height='5px' viewBox='0 0 10 5' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='7 10 12 15 17 10'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='3.0VE-Updates_UI-' transform='translate(-941.000000, -631.000000)'%3E%3Cg id='Group-7' transform='translate(8.000000, 267.000000)'%3E%3Cg id='Group-8' transform='translate(0.000000, 293.000000)'%3E%3Cg id='Icons/_arrows/arrow-drop-down' transform='translate(926.000000, 61.000000)'%3E%3Cg id='📐-z_-Padding/_null' transform='translate(-1.000000, -1.000000)'%3E%3C/g%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Mask' fill='%23FFFFFF' xlink:href='%23path-1'%3E%3C/use%3E%3Cg id='🎨Colour' mask='url(%23mask-2)' fill='%23FFFFFF'%3E%3Crect id='Rectangle' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    filter: grayscale(100%) brightness(50%);
  }

  &.ag-icon-asc {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10px' height='5px' viewBox='0 0 10 5' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpolygon id='path-1' points='12 9 7 14 16.999 14'%3e%3c/polygon%3e%3c/defs%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='37.2-Comparison_Loading' transform='translate(-348.000000, -1098.000000)'%3e%3cg id='Icons/_arrows/arrow-drop-down' transform='translate(341.000000, 1089.000000)'%3e%3cg id='📐-z_-Padding/_null' transform='translate(-1.000000, -1.000000)'%3e%3c/g%3e%3cmask id='mask-2' fill='white'%3e%3cuse xlink:href='%23path-1'%3e%3c/use%3e%3c/mask%3e%3cuse id='Mask' fill='%23FFFFFF' xlink:href='%23path-1'%3e%3c/use%3e%3cg id='🎨Colour' mask='url(%23mask-2)' fill='%23FFFFFF'%3e%3crect id='Rectangle' x='0' y='0' width='24' height='24'%3e%3c/rect%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    filter: grayscale(100%) brightness(50%);
  }

  &:before {
    display: none;
  }
}
