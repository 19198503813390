@import 'src/sass/ve.scss';

.bunker-ports-table {
    border: 2px solid $maritech-background-black;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--filter {
            display: flex;
            margin-bottom: $guttering-medium;
            border: 1px solid $maritech-white;

            &:focus-within {
                border-color: $maritech-action;
            }

            &-icon {
                color: $maritech-white;
                background: $maritech-background-black;
                padding-left: $guttering-tiny;
            }

            &-input {
                border: none;
                outline: none;
                height: 30px;
                width: 800px;
                font-size: 14px;
                padding: $guttering-tiny;
                color: $font-color-primary;
                background-color: $maritech-background-black;
            }
        }

        &--close-btn {
            margin-bottom: $guttering-medium;
        }
    }

    &__table-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 8px;
    }

    &__row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__body {
        height: calc(100vh - 255px);
        @include styled-scrollbar(8px);
        overflow-y: auto;
    }

    &--col {
        margin-right: $guttering-medium;
    }

    &--location-name {
        padding-left: $guttering-medium;
        min-width: 140px;
        text-overflow: ellipsis;
    }

    &--zone {
        min-width: 84px;
    }

    &--delivery {
        min-width: 54px;
        text-align: center;

        .icon-only-button {
            color: green;
        }
    }

    &--price {
        min-width: 70px;
        text-align: right;
    }

    &--date-issued {
        min-width: 70px;
        text-align: left;
    }

    &--import {
        font-weight: $font-weight-bold;
        font-family: 'Roboto', 'Helvetica', sans-serif;
        color: $maritech-action;
        padding: 2px 16px 2px 8px;
        min-width: 75px;
        height: 30px;

        &-header {
            text-align: center;
        }

        &:hover,
        &:active {
            color: $clarksons-action-darker-1;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}
