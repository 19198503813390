@import 'src/sass/ve.scss';

.version-mismatch-content {
  padding: $guttering-large $guttering-massive;
  color: $font-color-primary;

  &__header {
    padding-top: $guttering-small;
    display: flex;
    align-items: center;
    font-size: $font-size-subheader;
    font-weight: normal;

    .has-icon {
      color: $maritech-warn;
    }

    * {
      display: inline-block;
    }
  }

  &__text {
    font-size: $font-size-body-1;
    padding-left: 32px;
    padding-bottom: $guttering-small;
  }

  &__actions {
    text-align: center;
  }
}
