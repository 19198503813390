
@import 'src/sass/ve.scss';

$unpinned-header-plus-action-bar: $sea-header-height-unpinned + $action-bar-height;
$pinned-header-plus-action-bar: $sea-header-height-pinned + $action-bar-height;
$workbook-panel-height: calc(100vh - #{$unpinned-header-plus-action-bar});
$workbook-panel-height-on-expanded-nav-header: calc(100vh - #{$pinned-header-plus-action-bar});

.ag-header-cell:not(:last-child) > .ag-header-cell-comp-wrapper > .worksheet-header {
    border-right: 1px solid rgba($maritech-white, 0.5);
  }

.worksheet-header {
  display: flex;
  padding: 0 $guttering-small;
  width: 100%;
  height: 100%;
  align-items: center;
}

.grid-height{
  height: calc(calc(96vh) - 300px);
  padding: 20px 0;
  @at-root body.pinned--open #{&} {
    height: calc(calc(107vh - #{$pinned-header-plus-action-bar}) - 300px);
  }

  &--shorter{
    @at-root body.pinned--open #{&} {
      height: calc(calc(102vh - #{$pinned-header-plus-action-bar}) - 300px);
    }

    height: calc(calc(105vh - #{$pinned-header-plus-action-bar}) - 300px);
  }
}

.progress-bar{
  margin-top: 320px;
}

.ag-tooltip {
  background: $maritech-background-blue !important;
  color: $maritech-white !important;
  border: 1px solid $maritech-action !important;
  z-index: 9999;
  padding: $guttering-small;
  border-radius: 4px;
  width: 200px;
  white-space: initial;
  word-break: normal; 
  word-wrap: break-word;
}
