@import 'src/sass/ve.scss';

.overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: calc(100vw + 16px);
  height: 100%;
  left: -16px;
  top: 0;
  opacity: 0;
  z-index: $in-app-modal-dialog-z-index - 100;
  visibility: hidden;
  transition: visibility 0s 500ms, opacity 250ms linear 250ms;

  &-show {
    visibility: visible;
    opacity: 1;
    transition: opacity 250ms linear;
  }
}
