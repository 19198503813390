@import 'src/sass/ve.scss';

$splash-screen-height: 320px;
$splash-screen-width: 506px;

.splash-screen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: $splash-screen-width;
  background-color: $maritech-background-blue;
  border-radius: 10px;
  padding: $guttering-small;

  &__logo {
    display: block;
    margin: $guttering-medium;
    height: 60px;
  }

  &__content {
    display: flex;
    flex-flow: column;
    min-height: 203px;
    margin: $guttering-small;

    &__copy {
      font-size: $font-size-body-1;
      color: $font-color-primary;
      font-weight: $font-weight-regular;
      text-align: center;
      margin-top: auto;
    }
  }
}
