@import 'src/sass/ve.scss';

h2 {
  color: $maritech-gold;
  font-size: $font-size-subheader;
  font-weight: normal;
  margin-top: 0;
}

h3 {
  /*Literal color is needed while we're on 0.7.X. TODO: Change to $font-color-primary once on `clarksons-design-system` 0.8, which [has that variable bugfixed to this colour](https://dev.azure.com/clarksonscloud/Sea%20Design%20Assets/_git/design-system/commit/4542f3cbd9a357bed6576bcebbd55220e6a0ed7f?refName=refs%2Fheads%2Fmaster)*/
  color: $font-color-primary;
  font-weight: $font-weight-medium;
}

.title {
  color: $font-color-primary;
  font-size: $font-size-title;
  font-weight: normal;
  margin-bottom: $guttering-small;
}

.header {
  color: $maritech-gold;
  font-size: $font-size-subheader;
  font-weight: normal;
  margin-bottom: $guttering-medium;
  font-family: 'Cera', 'Roboto', 'Helvetica', sans-serif;
}

.sub-header {
  font-size: $font-size-body-2;
  margin-bottom: $guttering-medium;
  font-family: 'Cera', 'Roboto', 'Helvetica', sans-serif;
}
