@import 'src/sass/ve.scss';

.map {
  &-container {
    padding: $guttering-small 0 $guttering-small $guttering-medium;
    display: flex;
    flex-grow: 1;
    margin-right: -8px;

    .scrollable-panel {
      max-height: 100%;
    }
  }

  &-header {
    display: flex;
    margin-bottom: $guttering-small;
    padding: $guttering-small 0;
    align-items: center;

    &--title {
      margin: 0;
    }

    &--close-btn {
      margin-left: auto;
    }
  }
}
