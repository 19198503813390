@import 'src/sass/ve.scss';

.voyage-detail-table {
  margin-top: $guttering-medium;

  &--voyage-leg {
    min-width: 287px;
    padding-left: $guttering-small-medium;
  }

  &--waypoints {
    width: 100%;
  }
}
