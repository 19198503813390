@import 'src/sass/ve.scss';

.dropdown {
  position: relative;

  &-label {
    display: inline-block;
  }

  & &__display {
    height: 30px;
    background: $maritech-background-black;
    color: $font-color-primary;
    padding: 0 24px 0 $guttering-small;
    text-align: left;
    border: 1px solid $maritech-white;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    outline: none;

    &:focus {
      border-color: $maritech-action;
    }

    &--icon {
      font-style: normal;
      position: absolute;
      top: 0;
      right: 0;
    }

    &.tanker-dropdown {
      background:  #FFFFFF15;
      border: 1px solid #FFFFFF15;
      color: $font-color-tertiary;

      &:focus {
        border-color: $maritech-action;
      }
    }
  }

  &-label &__display {
    height: 30px;
    background: none;
    color: $font-color-primary;
    padding: 0 24px 0 $guttering-small;
    text-align: left;
    border: none;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    outline: none;

    &:focus {
      color: $maritech-action;
      border-color: $maritech-action;
    }

    &--icon {
      color: $font-color-primary;
      font-style: normal;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &,
  &-label {
    & &__menu {
      border-radius: 0;
      border: 1px solid $maritech-action;
      position: absolute;
      z-index: 10;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      background: $maritech-background-black;

      &-item {
        color: $font-color-primary;
        background: $maritech-background-black;
        padding: 8px 10px;
        display: inline-flex;
        white-space: nowrap;
        &--with-line {
          display: block;
          border-bottom: 3px solid #9a6e3a; // Use a variable or color that fits your theme
        }
        &--highlighted {
          background: $maritech-blue-dark;
        }
        &-separator {
            height: 1px;
            background-color: #FFFFFF15;
            margin: 0 8px;

          }
      }

    }
  }

  & &__menu {
    min-width: 100%;
  }
}

