@import 'src/sass/ve.scss';

.primary-button {
  @extend %action-button;
}

.secondary-button {
  @extend %flat-button;
}

.primary-button,
.secondary-button, {
  &[class*='icon--'] {
    @extend %icon-button;
  }
}

.icon-only-button {
  @extend %icon-only-button;
  color: $maritech-white;
  padding: 0;
  height: 100%;
  min-width: 
  /*Although the height is 32px (as of "0.7.3") it seems a bit off (vertically stretched & auto-centered icons end up higher than they should while 24.4375, which we examined from the computed heights of icons, seems to be the better height, but it also seems to produce a proper minimum width. */ 24.4375px;
  width: auto;

  &::before {
    margin-bottom: 0;
    margin-top: 0;
    height: unset
      /*TODO reconcile this with [the design-system's 32px](https://dev.azure.com/clarksonscloud/Sea%20Design%20Assets/_git/design-system?path=%2Fsrc%2Fsass%2Fcomponents%2Fcomponent.button.icon-only.scss&version=GC597a46cbd6e9877181b47c1166b0db2f5cf1ffa8&line=36&lineEnd=36&lineStartColumn=5&lineEndColumn=17&lineStyle=plain), which actually makes the icons vertically misaligned */;
  }

  &:disabled {
    cursor: not-allowed;
  }

  /* TODO - remove once moving to a version that addressed [this bug](https://teams.microsoft.com/l/message/19:581f9e1369d94a259a3c8a51024b2c40@thread.skype/1602694205124?tenantId=ced47db0-d73a-4514-a74d-63af7885ff7d&groupId=089b6bec-f379-418e-9f95-a9b97ab5602b&parentMessageId=1602694205124&teamName=Sea%2F%20Design%20System&channelName=Support&createdTime=1602694205124) */
  &:disabled::before {
    color: $icon-button-disabled;
  }
  &:hover,
  &:focus {
    color: $icon-button-hover;
  }
  /*Note that there's a separate issue in design system where `active` is specified like a class, with a dot: `.active`. When removing these, make sure it is fixed. It was raised [here](https://teams.microsoft.com/l/message/19:581f9e1369d94a259a3c8a51024b2c40@thread.skype/1603203582662?tenantId=ced47db0-d73a-4514-a74d-63af7885ff7d&groupId=089b6bec-f379-418e-9f95-a9b97ab5602b&parentMessageId=1602694205124&teamName=Sea%2F%20Design%20System&channelName=Support&createdTime=1603203582662)*/
  &:active::before {
    color: $icon-button-active;
  }
}


.gg-icon-only-button {
  cursor: pointer;
  padding: 0;
  height: 100%;
  width: auto;
  background-color: transparent;
  color: $maritech-white;
  &::before {
    margin-bottom: 0;
    margin-top: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled::before {
    color: $icon-button-disabled;
  }
  &:hover,
  &:focus {
    color: $icon-button-hover;
  }
  &:active::before {
    color: $icon-button-active;
  }
}

.material-icons {
  @extend %icon-only-button;
  color: $maritech-white;
  padding: 0;
  height: 100%;
  width: auto;

  &:disabled {
    cursor: not-allowed;
    color: $icon-button-disabled !important;
  }
  &:hover {
    color: $icon-button-hover;
  }
   &:active {
    color: $icon-button-active !important;
  }
}

