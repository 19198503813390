@import 'src/sass/ve.scss';

.worksheet-section__property {
  & + & {
    margin-left: $guttering-small-medium;
  }
  &-input-TC {
   width: 80px;
  }
}
