@import 'src/sass/ve.scss';

.delete-with-confirmation-button {
  position: relative;

  &__confirmation {
    @include can-animate('background-color');
    position: absolute;
    border: none;
    outline: none;
    width: 0;
    top: 0;
    right: 24px;
    visibility: hidden;
    text-transform: uppercase;
    height: 100%;
    vertical-align: middle;
    background-color: transparent;
    cursor: pointer;

    &--visible {
      width: 86px;
      color: #fff;
      background-color: $maritech-warn;
      visibility: visible;
    }

    &:hover,
    &:focus {
      background-color: shade($maritech-warn, $color-very-dark);
    }
  }

  &__icon {
    height: 30px !important;

    &:hover {
      color: $maritech-warn;
    }

    &--confirmed {
      background: $maritech-warn;
      color: #fff !important;

      &:hover {
        color: #fff !important;
      }
    }
  }
}
