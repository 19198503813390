@import 'src/sass/ve.scss';

.worksheet__panel-wrapper {
  flex-grow: 1;
  display: flex;
  height: calc(100% - 102px);
  margin-right: -8px;

  .scrollable-panel {
    height: 100%;

    & > .panel {
      padding-right: 8px;
    }
  }
}

.worksheet-section {
  &--route {
    width: 1238px;
  }

  &--cargo-rates,
  &--general-TC,
  &--costs,
  &--rates,
  &--addition-freight {
    display: flex;
    flex-direction: row;
  }

  &--commission {
    width: 230px;
  }

  &--tanker {
    width: 500px;
  }

  &--bunker-prices {
    max-width: 400px;
  }

  &--freight-income {
    width: 385px;
  }
  &--other-costs {
    width: 400px;
  }

  &__property {
    display: inline-flex;
    flex-direction: column;

    & + & {
      margin-left: $guttering-small;
    }

    &-label {
      font-size: $font-size-caption;
      margin-bottom: $guttering-small;
      position: relative;

      .dropdown-label {
        position: absolute;
        bottom: -$guttering-small;
      }

      &--immersion {
        margin-top: 13px;

        .dropdown__display {
          padding-left: 0;
          color: $clarksons-white;

          &--icon {
            color: $clarksons-white;
          }

          &:focus {
            color: $maritech-action;
            border-color: $maritech-action;
            .dropdown__display--icon {
              color: $maritech-action;
            }
          }
        }

        .dropdown__menu {
          min-width: 161px;
        }

        .dropdown__menu-item {
          background: $maritech-background-blue;
          &--highlighted {
            background: $maritech-blue-dark;
          }
        }
      }
    }

    &--rates {
      max-width: 96px;
    }

    &__large-input {
      width: 150px;
    }

    &__very-small-input {
      width: 80px;
    }
  }
}

.transparent-divider {
  background: transparent;
}

.tankers-intake-input {
  width: 100px;
}
.worksheet-section-wrapper {
  display: flex;
  flex-direction: column;

  &__components {
    display: flex;
    flex-direction: row;
  }

  &--rates {
    margin-left: $guttering-medium;
    padding-left: $guttering-medium;
    border-left: 1px solid $maritech-gold;
  }
}
