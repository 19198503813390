@import 'src/sass/ve.scss';

.worksheet-info {
  &__container {
    display: flex;
    justify-content: space-between;
    overflow: visible;
    padding-left: 16px;
    padding-right: 8px;
  }

  &__details {
    display: flex;
    align-items: flex-end;
  }

  &__speed-and-consumption-mode {
    position: relative;
    border-left: 1px solid $maritech-white;
    margin-left: $guttering-medium;
    margin-bottom: $guttering-tiny;
    &-label {
      display: flex;
      align-items: center;
      margin-left: $guttering-small;
      &-tooltip {
        height: 24px;
        width: 30px;
        &-text {
          width: 195px;
        }
      }
    }
  }

  &__name {
    max-width: 250px;

    &-label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 200px;

      &:hover:not([aria-readonly='true']) {
        border-bottom: 1px dotted $maritech-action;
      }
    }

    &-input {
      display: inline;
      min-width: 200px;
    }
  }

  &__controls {
    display: flex;
    &--button {
      display: inline-block;
      margin: 0 $guttering-small;
    }
  }
}

.copy-move-menu {
  position: relative;
  z-index: 100;

  &-hide {
    display: none;
  }

  &_content {
    position: absolute;
    border: 1px solid $maritech-white;
    background: $maritech-background-black;
    overflow-y: auto;
  }

  &_item {
    height: 30px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 $guttering-small;
    &__bottom-divider {
      margin: 0 $guttering-medium;
      width: auto;
    }
    a,
    button {
      height: 30px;
      display: inline-block;
      vertical-align: middle;
      padding: 8px;
    }

    &:hover,
    &:focus {
      color: $icon-button-hover;
      background: $maritech-blue-dark;

      a,
      button {
        color: $icon-button-hover;
        background: $maritech-blue-dark;
      }
    }
  }

  &-open {
    color: $maritech-action;
  }

  &_link-button {
    background: none;
    border: none;
    outline: none;
    color: #a6adbc;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;

    &:hover,
    &:focus {
      color: $icon-button-hover;
    }
  }
}
