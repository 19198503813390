@import 'src/sass/ve.scss';

.labeled-input {
  display: flex;
  flex-direction: column;

  &--label {
    font-size: $font-size-caption;
    margin-bottom: $guttering-small;
    position: relative;

    .dropdown-label {
      position: absolute;
      bottom: -$guttering-small;
    }

    &--immersion {
      height: 13px;

      .dropdown__display {
        padding-left: 0;
      }

      .dropdown__menu {
        min-width: 97px;
      }
    }
  }
}

.intake-calc {
  &__row {
    display: flex;
    padding: $guttering-medium 0;

    &-first {
      padding-top: 0;
    }

    &-reverse {
      flex-direction: row-reverse;
    }
  }

  &__vessel {
    margin-right: $guttering-medium;
    width: 250px;
  }

  &__deadweight {
    margin-right: $guttering-medium;
    width: 98px;
  }

  &__vessel-draft {
    width: 62px;
  }

  &__grain {
    width: 140px;
    margin-right: $guttering-medium;
  }

  &__tpcmi {
    width: 100px;
  }

  &__cargo {
    margin-right: $guttering-medium;
    width: 250px;
  }

  &__stowage {
    width: 132px;
  }

  &__location {
    margin-right: $guttering-medium;
    width: 250px;
  }

  &__port-draft {
    margin-right: $guttering-medium;
    width: 60px;
  }

  &__salinity {
    width: 150px;

    &--wrapper {
      display: flex;
    }

    &--select {
      min-width: 95px;
    }
  }

  &__constants {
    margin-right: $guttering-medium;
    width: 120px;
  }

  &__bunkers-on-board {
    margin-right: $guttering-medium;
    width: 120px;
  }

  &__fresh-water {
    width: 120px;
  }

  &__result {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &--label {
      font-size: $font-size-subheader;
    }

    &--value {
      font-size: $font-size-headline;
      color: $maritech-gold;
    }
  }

  &__actions {
    justify-content: flex-end;
    padding: 0;
  }
}

.tankers-intake {
  min-width: 134px;
  max-width: 134px;

  &__small {
    width: 120px;
  }
}

.tankers-intake-port-location {
  max-width: 110px;

  &__small {
    width: 110px;
  }
}
.salinity-tanker-input { 
  min-width: 60px;
}
