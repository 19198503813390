@import 'src/sass/ve.scss';
$border-separation: 1px solid $maritech-white;

.table-bottom {
  border-bottom: $border-separation;
}

.summary-income-section {
  margin: $guttering-medium $guttering-medium 0 $guttering-medium;
  padding-bottom: $guttering-medium;
  border-bottom: 1px solid $maritech-gold;

  h2 {
    padding-bottom: $guttering-small;
    border-bottom: 1px solid $maritech-gold;
  }
}

.summary-income-data {
  display: table;
  border-collapse: collapse;
  width: 100%;

  &__row {
    display: table-row;

    &-cell {
      width: 60px;
      display: table-cell;
      vertical-align: middle;

      &--right {
        text-align: right;
      }

      &--calculated {
        color: $maritech-gold;
      }
    }

    &-cell {
      padding: $guttering-tiny;
    }
  }

  &__footer {
    display: table-row;

    &-cell {
      display: table-cell;
      padding: $guttering-small $guttering-tiny;

      &--right {
        text-align: right;
        color: $maritech-gold;
      }
    }
  }
}
