@import 'src/sass/ve.scss';

@keyframes stripe-in-out {
  0% {
    opacity: 0;
    transform: skewX(-30deg) scale(0.6);
  }

  20% {
    opacity: 0.8;
    transform: skewX(-30deg) scale(1);
  }

  45% {
    opacity: 0;
    transform: skewX(-30deg) scale(0.6);
  }

  100% {
    opacity: 0;
    transform: skewX(-30deg) scale(0.6);
  }
}

$xsmall: 12;
$small: 16;
$medium: 24;
$large: 32; // Default
$xlarge: 40;

@function getHeight($size) {
  @return $size * 1px;
}

@function getWidth($size) {
  $dimensionsRatio: 2.25;
  @return $size * $dimensionsRatio * 1px;
}

.striped-loader {
  display: flex;
  flex-direction: row;
  margin: auto;
  height: getHeight($large);
  width: getWidth($large);

  &--xsmall {
    height: getHeight($xsmall);
    width: getWidth($xsmall);
  }

  &--small {
    height: getHeight($small);
    width: getWidth($small);
  }

  &--medium {
    height: getHeight($medium);
    width: getWidth($medium);
  }

  &--large {
    height: getHeight($large);
    width: getWidth($large);
  }

  &--xlarge {
    height: getHeight($xlarge);
    width: getWidth($xlarge);
  }
}

$frame-delays: 0s, 0.2s, 0.4s, 0.6s, 0.8s;

.stripe {
  box-sizing: border-box;
  height: 100%;
  padding: 0 4%;
  width: 20%;

  &-inner {
    animation-duration: 1.44s;
    animation-iteration-count: infinite;
    animation-name: stripe-in-out;
    animation-timing-function: ease-in-out;
    background: $maritech-action;
    height: 100%;
    opacity: 0;
    width: 100%;
  }

  @for $frame from 1 through length($frame-delays) {
    &:nth-child(#{$frame}) &-inner {
      animation-delay: nth($frame-delays, $frame);
    }
  }
}
