@import 'src/sass/ve.scss';

.notices-list {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  &::-webkit-scrollbar {
    // Make the scrollbar smaller and less obtrusive, since above `overflow-y: scroll` makes it visible all the time. The width was borrowed from the Sea/fort App, which uses [https://semantic-ui.com/behaviors/visibility.html#/examples]
    width: 10px;
  }

  max-height: 12em;

  .hide-notice {
    display: none !important;
  }

  .notice {
    & + .notice {
      margin-top: $guttering-tiny;
    }
  }
}
