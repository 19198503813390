@import 'src/sass/ve.scss';

.voyage-leg__container {
  display: flex;
  flex-direction: row;
  .fuel-grade {
    min-width: 100px;
  }

  .distance-input {
    width: 100px;
  }

  .cargosize-input {
    width: 110px;
  }

  .speed-input {
    width: 75px;
  }

  .cons-input {
    width: 110px;
  }

  .days-input {
    width: 70px;
  }

  .eta-input {
    width: 84px;
  }

  .port-costs-input {
    width: 110px;
  }

  .legType-label-dropdown {

    .dropdown__menu {
      width: 166px;
    }
  }

  .wet-lite-cell-placeholder {
    background-color: #D9D9D9 !important;
    opacity: 0.05;
  }
}
