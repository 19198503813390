@import 'src/sass/ve.scss';

$dot-radius: 7px;
$vertical-offset: 35px;

.voyage-container {
  margin-left: 24px;

  .voyage-leg {
    display: flex;

    &:first-child {
      display: flex;
      height: 36px;

      .timeline {
        &::after {
          height: 0 !important;
        }
      }
    }

    &:last-child {
      margin: 16px 0 0 0;
      height: 27px;

      .timeline {
        .dot {
          top: 0;
        }
      }
    }

    .port-of-call-top-container {
      margin-left: 30px;
      margin-top: -1px;
      display: flex;
      align-items: flex-start;

      .ve-checkbox {
        margin-right: 4px;
      }

      label {
        font-size: 13px;
        line-height: 20px;
      }

    }

    .port-of-call-bottom-container {
      margin-left: 30px;
      margin-top: -1px;
      display: flex;
      align-items: flex-start;

      .ve-checkbox {
        margin-right: 4px;
      }

      label {
        font-size: 13px;
        line-height: 20px;
      }
    }

    .open-location-container {
      margin-left: 60px;
      height: 26px
    }
  }

  .timeline {
    position: relative;
    width: 20px;

    .dot {
      border: 1px solid $maritech-gold;
      background-color: $maritech-gold;
      border-radius: 50%;
      height: calc(#{$dot-radius} * 2);
      left: calc(50% - #{$dot-radius});
      position: absolute;
      top: 0;
      width: calc(#{$dot-radius} * 2);
      opacity: 0.5;
    }

    .empty-dot {
      background-color: transparent;
      opacity: 1;
    }
    .dimmed-dot {
      background-color: transparent;
      opacity: 0.5;
    }

    &::after {
      content: '';
      height: calc(150% - #{$dot-radius} * 2 - 4px);
      left: calc(50% - 1px);
      position: absolute;
      bottom: 27px;
      width: 2px;

      border-left: 2px dashed $maritech-gold;
      opacity: 0.5;
    }
  }

  .main-voyage-leg {
    display: flex;
    height: 32px;

    .main-timeline {
      position: relative;
      min-width: 20px;

      &--first::after {
        height: 62px !important;
      }

      .dot {
        border: 1px solid $maritech-gold;
        background-color: $maritech-gold;
        border-radius: 50%;
        height: calc(#{$dot-radius} * 2);
        left: calc(50% - #{$dot-radius});
        position: absolute;
        top: calc(#{16px} - #{$dot-radius});
        width: calc(#{$dot-radius} * 2);
      }

      .empty-dot {
        background-color: transparent;
        opacity: 1;
      }

      &::after {
        background-color: $maritech-gold;
        content: '';
        height: calc(100% - #{$dot-radius} * 2 + 1px);
        left: calc(50% - 1px);
        position: absolute;
        bottom: calc(#{16px} + #{$dot-radius} - 1px);
        width: 2px;
      }

      &.dotted-timeline::after {
        background-color: transparent;
        border-left: 2px dashed $maritech-gold;
        opacity: 0.5;
        height: calc(100% * 2 - #{$dot-radius});
        bottom: 26px;
      }

      .eua-label {
        color: $maritech-gold;
        left: -30px;
        position: absolute;
        width: 30px;
        text-align: center;
        top: -5px;

        &--first {
          top:-40px;
        }
      }
    }
  }
}

.eua-label {
  color: $maritech-gold;
  left: -30px;
  position: absolute;
  width: 30px;
  text-align: center;
  top: -5px;
}
