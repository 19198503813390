@import 'src/sass/ve.scss';

.map-legend {
  display: flex;
  align-items: center;
  padding: $guttering-medium $guttering-small-medium;
  border-bottom: 1px solid #536476;

  .legend {
    padding-left: $guttering-medium;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;

    &__label {
      padding-left: $guttering-small;
    }
  }
}
