@import 'src/sass/ve.scss';

.divider {
  border: 0;
  height: 1px;
  margin: 0;
  width: 100%;
  background: $maritech-white;

  &__golden {
    background: $maritech-gold;
  }
}
