@import 'src/sass/ve.scss';

.numeric-input {
  @extend .calc-input;

  text-align: right;

  &--empty-value-in-mandatory-field {
    border-color: $maritech-gold;
  }

  &--readonly {
    border-color: #A6ADBC;
    color: $font-color-secondary;
    background: none;
  }
}
