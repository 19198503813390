@import 'src/sass/ve.scss';

.action-bar {
  display: flex;
  min-height: $action-bar-height;
  background: $maritech-background-blue;
  border-width: 0 0 $guttering-micro;
  border-style: solid none;
  border-color: $maritech-border-color;
  padding-top: $guttering-tiny;

  &__item {
    display: flex;
    padding: 0 $guttering-small;
  }
}
