@import 'src/sass/ve.scss';

$dot-radius: 7px;
$vertical-offset: 35px;

.route-container {
  position: relative;

  .voyage-leg {
    display: flex;
    flex-direction: row;

    &__container {
      margin-left: -6px;

      .route-data {

        &__row-cell {
          display: inline-flex;
          flex-direction: column;
        }

        &--grip {
          width: 20px;
        }
      }
    }


  }

  .voyage-time-leg {
    height: 100px;
  }

  .timeline-voyage-leg {
    height: 100px;
  }

  .voyage-leg-last {
    .timeline::after {
      height: 0 !important;
    }
  }

  .previous-port-of-call {
    display: flex;
    height: 54px;

    .timeline {

      .dot{
        top: 0;
      }

      &::after {
        top: calc(#{$dot-radius} * 2) !important;
        height: calc((200% - #{$dot-radius} * 4 - 10px)) !important;
        border-left-style: dashed !important;
        opacity: 0.5;
      }
    }
  }

  .port-of-call-top-container {
    margin-left: 14px;
    margin-top: -6px;

    .port-of-call-top {
      margin-top: -6px;
    }

    label {
      font-size: 13px;
      line-height: 20px;
    }

  }

  .port-of-call-bottom-container {
    margin-left: 14px;

    .port-of-call-bottom {
      margin-top: -12px;
    }

    label {
      font-size: 13px;
      line-height: 20px;
    }
  }

  .next-port-of-call-leg {
    display: flex;
    flex-direction: row;
    height: 54px;
    margin-top: 20px;

    .timeline {
      .dot {
        top: 0;
      }

      &::after {
        height: 72px;
        top: calc(#{-$vertical-offset} * 2 - #{$dot-radius} + 2px);
        border-left-style: dashed !important;
        opacity: 0.5;
      }
    }
  }

  .timeline {
    position: relative;
    width: 20px;
    left: -2px;

    .dot {
      border: 1px solid $maritech-gold;
      border-radius: 50%;
      height: calc(#{$dot-radius} * 2);
      left: calc(50% - #{$dot-radius});
      position: absolute;
      top: calc(#{$vertical-offset} - #{$dot-radius});
      width: calc(#{$dot-radius} * 2);
    }

    .solid-dot {
      background-color: $maritech-gold;
    }

    .solid-secondary-dot {
      background-color: $maritech-gold;
      opacity: 0.5;
    }


    &::after {
      border-left: 2px solid $maritech-gold;
      content: '';
      height: calc(100% - #{$dot-radius} * 2 + 1px);
      left: calc(50% - 1px);
      position: absolute;
      top: calc(#{$vertical-offset} + #{$dot-radius} - 1px);
      width: 2px;
    }

    .eua-label-left {
      color: $maritech-gold;
      left: -58px;
      top: -28px;
      position: absolute;
      width: 65px;
      text-align: center;
    }

    .eua-label-right {
      color: $maritech-gold;
      left: 30px;
      top: -28px;
      position: absolute;
      width: 120px;
    }
  }

  .dash-timeline {
    .timeline {
      &::after {
        border-left-style: dashed !important;
        opacity: 0.5;
      }
    }
  }

  .add-voyage-button-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: -3px;
  }
}
