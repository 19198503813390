@import 'src/sass/ve.scss';

.panel {
  padding: 0 $guttering-medium;
}

.panel-section {
  margin: $guttering-medium 0;
}

.panel-section .tankers-waypoints {
  margin: 0;
  padding-top: 18px;
}

.panel-section-element {
  padding: $guttering-small-medium $guttering-medium;

  & + & {
  margin-left: $guttering-small-medium;
  }
}
