.margin-top-micro {
  margin-top: $guttering-micro;
}

.margin-top-tiny {
  margin-top: $guttering-tiny;
}

.margin-top-small {
  margin-top: $guttering-small;
}

.margin-top-small-medium {
  margin-top: $guttering-small-medium;
}

.margin-top-medium {
  margin-top: $guttering-medium;
}

.margin-top-large {
  margin-top: $guttering-large;
}

.margin-right-small {
  margin-right: $guttering-small;
}

.margin-bottom-micro {
  margin-bottom: $guttering-micro;
}

.margin-bottom-tiny {
  margin-bottom: $guttering-tiny;
}

.margin-bottom-small {
  margin-bottom: $guttering-small;
}

.margin-bottom-small-medium {
  margin-bottom: $guttering-small-medium;
}

.margin-bottom-medium {
  margin-bottom: $guttering-medium;
}
.margin-left-auto {
  margin-left: auto;
}
