@import 'src/sass/ve.scss';

.scrollable-panel {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  position: relative;
  border-top: 4px solid transparent;
  flex-direction: column;
  &[hide-horizontal-scroll='true'] {
    overflow-x: hidden;
  }
  &[data-has-scrolled='true'] {
    // border-top: 4px solid rgba(0, 0, 0, 0.2);
    &:before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      height: 1px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
}

.panel,
.scrollable-panel {
  @include styled-scrollbar(8px);
  @include show-scrollbar-only-on-hover();
}

.reset-scroll-button {
  position: absolute;
  bottom: 20px;
  height: 20px;
  line-height: 20px;
  display: none;
  width: 20px;
  right: 20px;
  font-size: 10px;
  text-transform: uppercase;
  background-color: white;
  color: black;
  transform: scale(0);
  opacity: 0.5;
  transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;

  &:before {
    content: 'Scroll to top';
    position: absolute;
    top: 0;
    transform: scale(0);
    white-space: nowrap;
    padding-left: 5px;
    border-radius: 3px;
    padding-right: 5px;
    letter-spacing: 0.5px;
    right: calc(100% + 5px);
    background-color: white;
    opacity: 0;
    transition: opacity 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    background-color: rgba(white, 1);
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.5);
    opacity: 1;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
}
