@import 'src/sass/ve.scss';

$brand-header-background-color: $maritech-background-black;
$brand-header-text-color: $maritech-white;

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;

  #navbar {
    /* z-index is especially needed for the notices-presenter or any of the in-app content with adjusted z-index to not overlay the 'expanded but not yet pinned' navigation menu. */
    z-index: $cross-app-nav-menu-z-index;
  }
}

.app-root {
  display: block;
  position: relative;
  flex-grow: 1;
  font-size: $font-size-default;
  color: $maritech-white;

  height: calc(100vh - 64px);

  @at-root body.pinned--open & {
    min-height: calc(100vh - #{$sea-header-height-pinned});
    max-height: calc(100vh - #{$sea-header-height-pinned});
    height: calc(100vh - #{$sea-header-height-pinned});
  }

  &--no-scroll {
    overflow: hidden;
  }

  .app-header-item {
    background-color: $brand-header-background-color;
    // Keep the app header above generic in-app dialogs, so that user can still read app notifications or navigate out of the app just like he can use brand navigation or the browser buttons like back, refresh.
    // -1 below allows shadow from the 'cross-app nav-menu' to drop on the app header, to make it clear this is app's header.
    z-index: $in-app-modal-dialog-z-index + 1;

    // Make the scrollbar more subtle. Consider doing it globally across the app, but here we only affect the header items.
    &::-webkit-scrollbar-thumb,
    & *::-webkit-scrollbar-thumb /* Scrollbar properties don't inherit by default - this descendant selector saves us from having to precisely target the element every time*/ {
      // These were borrowed from the Fort App, which uses [semantic-ui](https://semantic-ui.com/behaviors/visibility.html#/examples)
      border-radius: 5px;
      background-color: $brand-header-text-color;
    }
  }
}

// .fullscreen-notification {
//   position: fixed;
//   left: 0px;
//   top: 56px;
//   height: calc(100vh - 56px);
//   width: 100vw;
//   background-color: rgba(0, 0, 0, 0.2);

//   @at-root body.pinned--open & {
//     top: $sea-header-height-pinned;
//     height: calc(100% - #{$sea-header-height-pinned});
//   }

//   &__content {
//     width: 100%;
//   }
// }
