@import 'src/sass/ve.scss';

.commission {
  &__section {
    display: inline-flex;
    flex-direction: column;
  }

  &__margin-right {
    margin-right: 15px;
  }
}

.commission-percentage {
  width: 70px;
}

.commission-amount {
  margin-left: 12px;
  width: 112px;
}
