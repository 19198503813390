@import 'src/sass/ve.scss';

.working-day-dropdown {
  display: flex;
  position: relative;

  &__selector {
    height: $base-input-height;
    border: 1px solid $maritech-white;
    background: $maritech-background-black;
    color: $font-color-primary;
    text-align: left;
    padding: 0 24px 0 $guttering-small;
    width: 63%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    outline: none;

    &:focus {
      border-color: $maritech-action;
    }

    &--icon {
      font-style: normal;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__input {
    width: 37%;
  }

  &__menu {
    border-radius: 0;
    border: 1px solid $maritech-action;
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    top: $base-input-height;

    &-item {
      color: $font-color-primary;
      background: $maritech-background-black;
      padding: 8px 10px;
      display: inline-flex;

      &--part {
        display: inline-flex;
        justify-content: space-between;
        align-items: center left;
      }

      &--highlighted {
        background: $maritech-blue-dark;
      }

      &--id {
        width: 75px;

        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &--factor {
        width: 75px;

        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &--description {
        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
