@import 'src/sass/ve.scss';

.tankers-summary {
  .tankers-summary__section {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #202e3c;
    height: 210px;
  }
}
