@import 'src/sass/ve.scss';

.cargo-autocomplete {
  position: relative;
  width: 100%;

  &__input {
    height: 30px;
    border: 1px solid $maritech-white;
    background: $maritech-background-black;
    color: $font-color-primary;
    text-align: left;
    padding: 0 $guttering-small;
    outline: none;

    &:focus {
      border-color: $maritech-action;
    }
  }

  &__menu {
    border-radius: 0;
    border: 1px solid $maritech-action;
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &-item {
      color: $font-color-primary;
      background: $maritech-background-black;
      padding: 8px 10px;
      display: inline-flex;
      line-height: 1.5;

      &--part {
        display: inline-flex;
        justify-content: space-between;
        align-items: center left;
      }

      &--flag {
        width: 25px;
      }

      &--country {
        width: 150px;

        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &--zone {
        width: 50px;
      }

      &--highlighted {
        background: $maritech-blue-dark;
      }

      &--name,
      &--parent-type {
        width: 150px;

        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &--stowage {
        width: 90px;

        &-units {
          margin-left: 5px;
        }

        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &--aliases {
        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
