@import 'src/sass/ve.scss';


.edit-speed-and-cons-modal {
  display: flex;
  flex-direction: column;
  padding: $guttering-medium;
  padding-bottom: 0;
  width: 536px;
  height: 530px;

  &-with-generator{
    height: 602px;
    overflow-y: scroll;
    overflow-x: hidden;
    @include styled-scrollbar(8px);
    @include show-scrollbar-only-on-hover();
  }
  .speed-edit-part{
    padding-left: 19px;
    height: 60px;
    &__second{
      padding-top: 20px;
      padding-left: 19px;
      padding-bottom: 10px;
    }
  }
  .generator-toggle {
    justify-content: flex-end;
    display: flex;
    flex: 3;
    padding-right: 45px;
  }
  .modal-label__container {
    display: inline;
    width: 126px;
    height: 30px;
    background-color: #202E3F80;
    border: 1px solid #142232;
    margin-right: -12px;
    .text {
      text-align: left;
      margin-left: 10px;
      align-items: center;
      height: 30px;
      font-size: $font-size-subheader;
      margin-top: 5px;
    }
    &__fuel-grade{
      display: inline;
      width: 216px;
      height: 30px;
      background-color: #202E3F80;
      border: 1px solid #142232;
      margin-right: -12px;
      .text {
        text-align: left;
        margin-left: 10px;
        align-items: center;
        height: 30px;
        font-size: $font-size-subheader;
        margin-top: 4px;
      }
    }
  }

  .edit-input{
    width: 96px;
  }

  .fuel-grade-dropdown{
    width: 111px;
    .dropdown__display {
      background: #FFFFFF26;
      border: 1px solid #2e3945;
    }
  }
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $guttering-small;
    width: 100%;

    &__title {
      color: $font-color-primary;
      font-size: $guttering-large;
      font-family: Roboto;
      font-weight: 700;

    }
    &__subtitle{
      color: $font-color-primary;
      font-size: $guttering-medium-large;
      padding-top: 36px;
      padding-left: 19px;
      font-family: Roboto;
      font-weight: 600;
      margin-bottom: 0;
    }

    &__close-btn {
      margin-bottom: $guttering-small;
    }
  }
}
