@import 'src/sass/ve.scss';

.no-document-placeholder {
  display: flex;
  color: $font-colour-primary;
  &__type-icon::before {
    font-size: 40px;
    line-height: 0.6;
    margin-right: $guttering-small-medium;
  }
  &__user-message {
    font-size: $font-size-body-1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30em;
    line-height: 1.7em;
    header {
      align-self: flex-start;
      font-size: $font-size-headline;
    }
    footer {
      margin-top: $guttering-small-medium;
    }
  }
}
