@import 'src/sass/ve.scss';
// the width of the grid - calculated from the column widths defined in the vessels-grid.js
$vessels-grid-width: 1258px;

.vessels-grid {
  width: $vessels-grid-width;
  .ag-header.ag-focus-managed.ag-pivot-off {
    // prevent the truncation of vessel add button, within the vessels-grid header, when the browser is not maxmised
    //width: $vessels-grid-width;
  }

  /* Ag-grid's own CSS is setup such that overflowed content is hidden, within `cellRenderer`s, from being visible when it exceeds the boundaries. Ag-grid provides  `cellEditor` popup functionality to help get around this, this also requires setting the grid's popupParent, however this has it's own short comings. This means that you now have two ways of rendering the content within the cell, i.e. a `cellRenderer` and a `cellEditor`. This also means you must also have a means of keeping the visual state of both `cellRenderer` and `cellEditor` in sync when the grid toggles between them. Also as we are using master/detail, the detail has it's own drop down menus which can spill over content below it. Rather than go down this route, which would entail extra code and further tie us to ag-grid, chose to override the CSS instead. There could be possible side-effects down line, such as scrollbars showing up in unexpected places, however none seen thus far. */
  .ag-center-cols-clipper,
  .ag-cell-value,
  .ag-center-cols-container,
  .ag-header-viewport,
  .ag-floating-top-viewport,
  .ag-body-viewport,
  .ag-center-cols-viewport,
  .ag-floating-bottom-viewport,
  .ag-body-horizontal-scroll-viewport,
  .ag-virtual-list-viewport .ag-root.ag-layout-normal,
  .ag-root.ag-layout-auto-height,
  .ag-full-width-row,
  .ag-root-wrapper {
    overflow: visible;
  }

  .ag-layout-auto-height .ag-center-cols-clipper,
  .ag-layout-auto-height .ag-center-cols-container,
  .ag-layout-print .ag-center-cols-clipper,
  .ag-layout-print .ag-center-cols-container {
    /* found that after upgrading to aggrid v24.0.0 found an issue where they have specified `min-height`, see `@ag-grid-community\core\dist\styles\ag-grid.css:221`. This was done as part of [AG3390](https://github.com/ag-grid/ag-grid/blame/f011ffb0ad989acc9e43c9eb075c98e01e264668/community-modules/core/src/styles/structural/_common-structural.scss) to improve the auto-height sizing when 0 rows are rendered. Created an aggrid github [issue](https://github.com/ag-grid/ag-grid/issues/4095) to capture this.
    */
    min-height: unset;
  }

  $zIndexOfVesselDetails: 1;
  /* allow the drop downs within the vessel details to overlay the content that's referenced later in the tree */
  [role='presentation'] > [role='row'] {
    z-index: $zIndexOfVesselDetails;
  }
  /* Set the z-index +1 to allow the selected row's dropdown menus (e.g. vessel auto complete) to overlay the content that's referenced later in the tree (the row's own details, subsequent rows, etc). */
  [role='rowgroup'] > .ag-row-selected[role='row'] {
    z-index: $zIndexOfVesselDetails + 1;
  }

  .column {
    display: block;
    &-header-left,
    &-header-open-date,
    &-cell-left {
      margin-left: $guttering-tiny;
    }

    &-header-action * {
      width: 100%;
    }

    &-header-open-date * {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        border-right: none;
        margin-right: $guttering-micro;
      }
  }

  .ag-row {
    background-color: rgba(0, 0, 0, 0.25);
    width: $vessels-grid-width;
    border-color: rgba(0, 0, 0, 0.25);
    border-style: solid;
    border-width: 0 2px 2px 0px;
    z-index: 1;

    &-even {
      background-color: $maritech-background-light;
    }

    &-selected {
      .worksheet-vessel-grid-vesselstatus__icon {
        color: white;
      }
    }

    &__icon {
      &--invalid {
        color: $maritech-warn;
      }
      &--guidance {
        color: $maritech-orange;
      }
      margin-right: $guttering-small;
    }
  }

    .vessel-panel-multi-vessel-wrapper {
      margin-left: $guttering-tiny;
      margin-top: $guttering-small;
      margin-right: $guttering-tiny;
    }

  .ag-cell {
    max-height: 30px;
    border: none;
  }

  // make the style of the aggrid's scrollbars fit our theme
  .ag-body-viewport,
  .ag-body-horizontal-scroll-viewport {
    @include styled-scrollbar(10px);
  }

  .ag-cell-wrapper.ag-row-group {
    align-items: center;
    justify-content: center;
  }

  .actions-add-vessel{
    font-size: 22px;
  }

  .actions-delete-vessel {
    font-size: 22px;
    margin-right: 4px;
    &:hover {
      color: $maritech-warn;
    }
  }

  .dropdown {
    &.imo-2020__section-field {
      &.imo-2020__section-field--scrubber {
        // override existing style, just for the vessel row, so that the fields fills the cell it's in
        max-width: unset;
        .dropdown__menu {
          // TODO override existing style, however unsure as why this is necessary
          position: relative;
        }
      }
    }
  }

  .vessel-grid-grip {
    color: #aa7839;
  }
                                                                                                                                                                                                                    
  .ag-icon-grip {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 15px;
    width: 15px;
  }

  .ag-column-first {
    background-color: #112539;
    left: -6px !important;
    top: -2px;

   .ag-cell-wrapper{
      background-color: #112539;
      height: 34px;
    }
    
    :focus {
      background-color: #112539 !important;
    }
  }

  .ag-column-first:hover {
    background-color: #112539 !important
  }

  .vessel-grid-expand-collapse {
    .expand-collapse-button {
      // set height to match the adjacent cells like vessel-autocomplete and numeric-input
      height: 30px;
      // need have the border, otherwise the collapse arrow moves when focused
      border: transparent 1px solid;

      &:focus {
        border-color: $maritech-action;
      }

      &__icon {
        // override existing style
        line-height: unset;
      }
    }
  }

  .worksheet-vessel-grid-vesselstatus {
    // set height to match the adjacent cells like vessel-autocomplete and numeric-input
    height: 30px;
    &__icon {
      margin-top: $guttering-tiny;
      color: $maritech-orange;
    }
  }

  .column-cell-open-location {
    input {
      border-right: 0;
    }
  }

  .column-cell-open-date {
    display: flex;
    input {
      border-left-style: dashed;
      border-right: 0;
    }

    .vessel__open-position__refresh-button {
      @extend .calc-input-canvas;
      border-left-style: dashed;
      width: 32px;
    }
  }

  .column-cell-action {
    display: flex;
    justify-content: center;
    padding-left: 2px
  }

  .dropdown__menu-item {
    align-items: center;
  }
}

#vessel-status-icon-tooltip {
  margin-left: -7px;
}

#speed-cons-multi-vessel-audit-info-tooltip {
  /* override the tooltip placement to bring the tooltip closer to the speed and consumption icon */
  margin-top: 0;
}
