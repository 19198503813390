@import 'src/sass/ve.scss';

.link-button {
  background: none;
  border: none;
  outline: none;
  color: $maritech-white;
  cursor: pointer;
  padding: 0;
  text-decoration: underline dotted;
  text-underline-position: under;

  &:hover,
  &:focus {
    color: $icon-button-hover;
  }
  &:active {
    color: $maritech-action;
  }
}
