@import 'src/sass/ve.scss';

.workbook-autocomplete {
  position: relative;
  width: 100%;

  &__input {
    height: 44px;
    width: 498px;
    border: 1px solid $maritech-white;
    background: #000000;
    color: $font-color-primary;
    text-align: left;
    padding: 0 $guttering-massive;
    margin: 0 40px;
    outline: none;
    border-radius: $base-corner-radius;
    font-size: $font-size-body-2;

    &:focus {
      border-color: $maritech-action;
    }
  }

  &__menu {
    border-radius: 0;
    border: 1px solid $maritech-action;
    position: absolute;
    width: 498px;
    margin: 0 40px;
    padding-top: $guttering-medium;
    z-index: 11;
    color: $font-color-primary;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background: $maritech-background-black;

    &-header {
      display: flex;
      justify-content: space-between;
      padding: 0 28px $guttering-small $guttering-massive;

      .header-item-name {
        width: 164px;
      }
    }

    &-item {
      background: $maritech-background-black;
      padding: 8px 28px 8px 24px;
      display: flex;
      justify-content: space-between;

      &--symbol {
        padding: 1px 4px 1px 4px;
        border-radius: 800px;
        text-align: center;
        width: 5px;
        height: 5px;
        margin-right: 8px;
        margin-top: -2px;
        display: inline-table;
        font: 400 13px 'Roboto';
      }

      &--symbol-name {
        width: 248px;
        display: flex;
      }

      &--sheets {
        width: 10px;
      }

      &--update {
        width: 100px;
        text-align: end;
      }

      &--highlighted {
        background: $maritech-blue-dark;
      }
    }
    &-empty-state {
      color: #a6adbc;
      padding: 8px 28px 8px 24px;
      text-align: center;
      background: #0F4570;
    }
  }

  .custom-iconButton {
    position: absolute;
    left: 56px;
    top: 2px;
    pointer-events: none;
  }
}
