@import 'src/sass/ve.scss';

.vessel-panel-multi-vessel-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  .vessel-wrapper {
    flex: 1 auto;
    padding-right: $guttering-small;
    width: 100%;
  }

  .imo-wrapper {
    flex: 1 auto;
    padding-left: $guttering-small;
    border-left: 1px solid $maritech-white;
    min-width: 250px;
  }

  .rates__very-small-input {
    width: 80px;
  }
}
