@import 'src/sass/ve.scss';

.summary-estimate-data {
  display: table;
  border-collapse: collapse;
  width: 100%;
  text-align: right;

  &__header {
    display: table-row;
    border-bottom: 1px solid $maritech-white;

    &-cell {
      display: table-cell;
      padding: 0 $guttering-tiny $guttering-small $guttering-tiny;
      width: 35%;

      &:first-child {
        width: 30%;
      }
    }
  }

  &__row {
    display: table-row;

    &-cell-sm,
    &-cell {
      display: table-cell;
      padding: $guttering-small $guttering-tiny;

      &-value {
        color: $maritech-gold;
        font-weight: bold;

        &--active {
          color: $font-color-primary;
        }
      }
    }

    &-cell-sm {
      width: 12%;
    }

    &-border {
      border-top: 1px solid $maritech-white;
    }

    &:nth-child(2) &-cell {
      padding-top: $guttering-medium;
    }

    &:last-child &-cell {
      padding-bottom: 0;
    }
  }
}

.summary-estimate-footer-data {
  margin-top: $guttering-small;
}
