@import 'src/sass/ve.scss';
$border-separation: 1px solid $maritech-white;

.table-space {
  margin-top: 10px;
}
.table-bottom {
  border-bottom: $border-separation;
}

.calc-icon-tooltip {
  .formula {
    padding-bottom: 5px;
  }
}

.carbon-breakdown-section {
  width: 100%;
}

.summary-carbon-data {
  display: table;
  border-collapse: collapse;
  width: 100%;

  &__header {
    display: table-row;
    border-bottom: 1px solid $maritech-white;

    &-cell {
      display: table-cell;
      padding: 0 $guttering-tiny $guttering-small $guttering-tiny;

      &--right {
        text-align: right;
      }

      &--border {
        border-left: 1px solid;
        width: 81px;
      }
    }
  }

  &__row {
    display: table-row;

    &-cell-eeoi,
    &-cell {
      display: table-cell;
      vertical-align: middle;

      &--right {
        text-align: right;
      }

      &--calc-icon {
        text-align: right;
      }

      &--border {
        border-left: 1px solid $maritech-white;
      }

      &--highlight {
        color: $maritech-gold;
      }
    }

    &-cell {
      padding: $guttering-tiny;
      width: 180px;
    }

    &-cell-small {
      padding: $guttering-tiny;
    }

    &-cell-eeoi {
      padding: 8px 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 68px;
    }

    &:nth-child(even) {
      background-color: $maritech-background-blue;
    }
  }

  &__footer {
    display: table-row;
    border-top: 1px solid $maritech-white;

    &-cell {
      display: table-cell;
      padding: $guttering-small $guttering-tiny;

      &--highlight {
        color: $maritech-gold;
      }

      &--right {
        text-align: right;
      }
    }
  }

  &__note-section {
    width: 100%;
    display: inline-block;

    &-top {
      padding-top: 10px;
      &-left {
        float: left;
        margin-right: 5px;
      }

      &-right {
        float: left;
        opacity: 0.5;
        transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      }

      &-second {
       margin-top: 20px;
      }

    }

    &-tooltip {
      transform: translateY(-25%);
      width: 300px;
      &-text {
        text-align: left;
        float: left;
      }
    }

    &-bottom {
      padding-top: $guttering-tiny;
      text-align: left;
      width: 420px;
      &-left {
        padding: 21px 5px 0 0;
        float: left;
        width: 27%;
        display: block;
        text-align: left;
      }

      &-right {
        padding: 10px 0;
        float: left;
        width: 73%;
        display: block;

        .divider {
          height: 1px;
          margin: 3px 0;
        }

        .top-text {
          text-align: center;
          display: block;
        }

        .bottom-text {
          text-align: center;
          display: block;
          sub {
            font-size: 13px;
          }
        }
      }
    }
  }
}
