@import 'src/sass/ve.scss';

.modal {

  &-buttons {
    margin: $guttering-massive $guttering-large $guttering-large $guttering-large;
    display: flex;
    justify-content: flex-end;

    .primary-button {
      display: flex;
      &:focus-visible {
        background-color: #1b7bc7;
      }
    }

    .secondary-button {
      border: 1px solid $maritech-white;
      margin-right: $guttering-medium;
    }
  }

  &-button {
    margin: 0;
  }
}

.success-button {
  @extend %positive-button;
}

.warning-button {
  @extend %warning-button;
}

.modal-header {
  color: $font-color-primary;
  font-size: $guttering-large;
  padding: 28px $guttering-large 40px;
  display: flex;
}



