.checkboxes {
  display: flex;
  flex-direction: row;
  margin-top: 5px;

  .ve-checkbox {
    margin-right: 15px;
  }

  .empty-checkbox {
    width: 50px;
  }
}
