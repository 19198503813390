@import 'src/sass/ve.scss';

.editable-label {
  cursor: text;
  display: inline-block;
  padding: $guttering-micro 0;
  font-size: $font-size-subheader;
  border-bottom: 1px solid transparent;
  color: #fff;

  &__textbox {
    display: inline-block;
    padding: $guttering-micro 0;
    margin-bottom: 3px;
    color: $maritech-white;
    background: none;
    outline: none;
    font-size: $font-size-subheader;
    border: none;
    border-bottom: 1px dotted $maritech-action;
  }
}
