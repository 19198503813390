@import 'src/sass/ve.scss';

.port-autocomplete {
  position: relative;
  width: 100%;

  &--error {
    border: 1px solid $maritech-warn;
  }

  &__input {
    height: 30px;
    border: 1px solid $maritech-white;
    background: $maritech-background-black;
    color: $font-color-primary;
    text-align: left;
    padding: 0 $guttering-small;
    outline: none;

    &:focus {
      border-color: $maritech-action;
    }

    &--error:focus {
      border-color: transparent;
    }

    &-isMandatory {
      border-color: #F7CD46;
    }


    &-isReadonly {
      border-color: #A6ADBC;
      color: $font-color-secondary;
      background: none;
    }

    &-edit-button {
      position: absolute;
      top: 49%;
      right: 0;
      transform: translateY(-50%);
      align-items: center;
      background: none;
      border: none;
      color: $font-color-primary;
      padding: 0;
      cursor: pointer;
      outline: none;

      &--icon {
        $desiredSize: 14px;
        $originalSize: 24px;
        $scaleFactor: $desiredSize / $originalSize;
        transform: scale($scaleFactor);
        line-height: 1;
      }
    }

    &--tankerField-editable {
      background: #FFFFFF15;
      color: $font-color-tertiary;
      border: 1px solid #FFFFFF15;
      height: 30px;

      &[empty-mandatory-field='true'] {
        border: 1px solid #F7CD46;
      }
    }
  }

  .is-edited-mode {
    color: $font-color-primary;
  }

  &__menu {
    border-radius: 0;
    border: 1px solid $maritech-action;
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &-item {
      color: $font-color-primary;
      background: $maritech-background-black;
      padding: 8px 10px;
      display: inline-flex;

      &--part {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
      }

      &--flag {
        width: 25px;
      }

      &--name {
        width: 150px;

        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &--country {
        width: 100px;

        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &--zone {
        width: 75px;
      }

      &--aliases {
        max-width: 450px;

        &-inner {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &--highlighted {
        background: $maritech-blue-dark;
      }

      &--nothing-found-notice {
        width: 500px;
        background: $maritech-background-black;
        line-height: 15px;
        padding: 8px 10px;
        color: $maritech-white;
      }
    }

    &-error {
      width: 600px;
      border: none;
    }

  }


}
