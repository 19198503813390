@import 'src/sass/ve.scss';

.bunkers-section {
  display: flex;

  &-left {
    display: flex;
    flex: 1;
  }

  &-vert-line {
    width: 1px;
    background-color: #fff;
    opacity: 0.5;
    margin: 0 $guttering-medium $guttering-medium;
    align-self: normal;
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    padding-right: 2px;
  }

  &-button {
    margin-bottom: $guttering-medium;
  }
}

.bunkers-table {
  flex: 1 1 auto;
  overflow-y: auto;

  &-unused-col {
    opacity: 0.5;
    transition: opacity 0.5s ease-out;
  }

  &-disabled-col {
    color: $font-color-tertiary;
  }

  &-last-modified-info {
    .icon-button {}

    &-tooltip {
      min-width: 159px;
      width: 100%;

      &-highlight {
        color: white;
      }

      &-text {
        margin: 0;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }


  &-flex {
    display: flex;

    &-fixed-left {
      width: 220px;
      justify-content: flex-start;
      margin-bottom: 55px;
    }

    &-scrollable {
      overflow-y: auto;
      @include styled-scrollbar(8px);
      padding-right: 130px;
    }

    &-fixed-right {
      justify-content: flex-end;
    }

    &-header {

      &-location-col {
        height: 32px;
        width: 100%;
        border-bottom: 1px solid $maritech-white;
        padding: $guttering-small $guttering-small;
      }

      &-col {
        border-bottom: 1px solid $maritech-white;
        background-color: inherit;

        &-fuel-grade {
          width: 175px;
          border-left: 1px solid $maritech-white;

          &-title {
            font-weight: normal;
            padding-left: $guttering-small;
            width: 100%;
          }

          &-breakdown {
            display: flex;
            width: 100%;

            &-quantity {
              padding-left: $guttering-small;
              flex: 1;
            }

            &-price {
              flex: 1;
            }

            &-divider {
              padding: 0 $guttering-tiny;
              visibility: hidden;
            }
          }

          &-action {
            min-width: 32px;
            padding-left: 4px;
          }
        }
      }
    }

    &-row {
      display: flex;
      flex-wrap: nowrap;
      background-color: $maritech-background-black;
      height: $grid-row-height;
      align-items: center;
      width: inherit;

      &:nth-child(even) {
        background-color: $maritech-background-blue;
      }
    }

    &-col {
      background-color: inherit;
      text-align: center;
      line-height: 20px;
      font-size: 12px;

      &-location {
        flex: 1 1 0;
        padding-left: 8px;
      }

      &-fuel-grade {
        display: flex;
        align-items: center;
        width: 175px;

        &-quantity,
        &-price {
          flex: 1 1 0;
        }

        &-divider {
          padding: 0 $guttering-tiny;
        }
      }
    }
  }
}
