@import 'src/sass/ve.scss';

.action-bar__primary {
  display: flex;
  flex-direction: row;
  min-width: 1280px;
  max-height: 40px;
  margin-right: $guttering-medium;

  &--controls {
    padding: 0;
    margin-left: auto;
  }
}

.tabs-dropdown__tab--active {
  color: $maritech-action;
}

.tabs-dropdown-header {
  padding: 0 !important;
}
