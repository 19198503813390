@import 'src/sass/ve.scss';

$unpinned-header-plus-action-bar: $sea-header-height-unpinned + $action-bar-height;
$pinned-header-plus-action-bar: $sea-header-height-pinned + $action-bar-height;
$workbook-panel-top-content-incl-header-height: 131px;
$workbook-panel-top-content-incl-colleagues-toggle-height: $workbook-panel-top-content-incl-header-height + 70px;
$workbook-panel-my-workbooks-filter-height: 100px;
$workbook-panel-pre-my-workbooks-grid-content-height: $workbook-panel-top-content-incl-colleagues-toggle-height + $workbook-panel-my-workbooks-filter-height;
$workbook-panel-width: 1000px;
$input-border-width: 1px;
$input-border-def: $input-border-width solid $clarksons-border-darkest;

%workbook-name {
  width: 380px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

%updated-date {
  padding-left: $guttering-small;
}

$workbook-panel-height: calc(100vh - #{$unpinned-header-plus-action-bar});
$workbook-panel-height-on-expanded-nav-header: calc(100vh - #{$pinned-header-plus-action-bar});

.search {
  &-panel {
    position: absolute;
    width: $workbook-panel-width;
    /* Use min-height, because otherwise, if ag-grid overflows, it will break through this panel in a text-in-the-air fashion */
    min-height: $workbook-panel-height;

    @at-root body.pinned--open #{&} {
      min-height: $workbook-panel-height-on-expanded-nav-header;
    }

    padding: 0 !important;
    margin-top: 40px;
    left: -$workbook-panel-width;
    background: $maritech-background-blue;
    z-index: $in-app-modal-dialog-z-index - 50;
    transition: transform 250ms ease-in-out;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &--active {
      transition-delay: 250ms;
      transform: translate3d($workbook-panel-width, 0, 0);
    }

    &__header {
      margin-top: 10px;
      margin-bottom: 0 !important;
      display: flex;
    }

    &__divider {
      background-color: $font-color-primary;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      padding-right: 16px;
      padding-bottom: 5px;

      &-close-btn {
        color: $font-color-primary;
      }
    }

    &__actions-above-list {
      display: flex;
      justify-content: flex-end;
    }

    &__add-new {
      display: flex;
      flex-direction: column;
      width: 100%;

      &-btn.primary-button {
        margin-left: auto;
        margin-top: 0;
        margin-right: 0;
      }

      &-inputs-panel {
        width: 100%;
      }
    }

    &__symbol {
      padding: 1px 4px 1px 4px;
      border-radius: 800px;
      text-align: center;
      width: 5px;
      height: 5px;
      margin-right: 5px;
      margin-top: -1px;
      display: inline-table;
      font: 400 13px 'Roboto';
    }

    &-dropdown {
      padding-left: $guttering-medium;
    }

    &-tab-wrapper {
      display: flex;
      padding-left: 10px;
      justify-content: flex-start;

      button {
        div {
          font-weight: 400;
        }
      }

      &-search-tab {
        cursor: pointer;
        color: $maritech-white;
        padding: 0 $guttering-small-medium;
        background-color: $maritech-background-blue  !important;

        &__content {
          display: block;
          overflow: hidden;
          outline: none;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0 auto;
          width: 100%;

          &-hide {
            display: none;
          }

          &_inner {
            padding: 0 20px 0 20px;
            margin-bottom: 2px;
          }
        }

        &--active {
          color: $maritech-action;
        }

        &:hover,
        &:focus {
          color: $maritech-action;
        }

        &--errored {
          display: flex;
          align-items: center;
        }

        &__warning-icon {
          color: $maritech-warning;
          margin-right: $guttering-small;

          &::before {
            font-size: $guttering-medium  !important;
          }
        }
      }
    }
  }

  &-filter {
    display: flex;
    flex-direction: column;
    margin-bottom: $guttering-large;
    text-align: left;
    
    &__worksheet{
      padding: 0 20px;
      &--show{
        display: block;
      }
      &--hidden{
        display: none;
      }
    }

    &__colleague{
      &--show{
        display: block;
      }

      &--hidden{
        display: none;
      }
    }

    &__input {
      $icon-height: 18px;
      $icon-width: 18px;
      $icon-padding: 5px;
      $icon-top-empty-space: 1px;
      display: flex;

      &--icon {
        position: absolute;
        padding-left: $icon-padding;
        padding-right: $icon-padding;
        padding-top: $icon-padding + $input-border-width - $icon-top-empty-space;
        padding-bottom: $icon-padding + $input-border-width + $icon-top-empty-space;

        &::before {
          display: inline-block;
          height: $icon-height  !important;
          width: $icon-width  !important;
          font-size: 18px !important;
        }
      }

      &--box {
        padding-left: $icon-padding + $icon-width + $icon-padding;
        min-height: $input-border-width + $icon-padding + $icon-height + $icon-padding + $input-border-width;
        color: white;
        background: black;
        border: $input-border-def;

        &:focus {
          border-color: $maritech-action;
        }
      }
    }

    &__label-container {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      &--label{
        margin-right: 5px;
        margin-top: 3px;
      }
      &--tooltip-text{
        width: 200px;
        white-space: initial;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;

    .grid-height-determiner {
      /* ag-grid needs nominal height here - https://www.ag-grid.com/javascript-grid-width-and-height/ */
      height: calc(#{$workbook-panel-height} - #{$workbook-panel-pre-my-workbooks-grid-content-height});

      @at-root body.pinned--open #{&} {
        height: calc(#{$workbook-panel-height-on-expanded-nav-header} - #{$workbook-panel-pre-my-workbooks-grid-content-height});
      }
    }

    &__header {
      display: flex;
      padding: 0 $guttering-small;
      cursor: pointer;
      background: $maritech-background-black;
      min-height: 32px;

      &--col {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &--name {
        @extend %workbook-name;
        border-right: 1px solid rgba($maritech-white, 0.5);
        padding: $guttering-small $guttering-small $guttering-small 0;
      }

      &--updated-date {
        @extend %updated-date;
        padding: $guttering-small;
      }

      &--direction-icon::before {
        font-size: 16px !important;
      }
    }

    &__new {
      display: flex;
      flex-direction: column;
      padding: $guttering-small;
      min-height: 58px;
      justify-items: center;

      &--name {
        background: #154771;
        height: $guttering-large;
        color: $maritech-white;
        border: none;
        border-bottom: 1px dashed rgba($maritech-white, 0.5);
        outline: none;

        &:focus {
          color: $font-color-primary;
          border-color: $maritech-action;
        }
      }

      &--error-message {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        justify-items: initial;
      }
    }

    &__items {
      flex-grow: 1;
      overflow-y: auto;
    }

    &__item {
      display: flex;
      flex-direction: column;
      padding: $guttering-small;
      cursor: pointer;
      color: $maritech-white;
      text-decoration: none;

      &:hover,
      &:focus {
        background: $clarksons-action-darker-2;
        outline: none;
      }

      &--active {
        color: $font-color-primary;
        background: $maritech-action;

        &:hover,
        &:focus {
          color: $font-color-primary;
          background: $maritech-action;
        }
      }

      &-info {
        display: flex;

        &--name {
          @extend %workbook-name;
          font-size: $font-size-subheader;
          line-height: 24px;
          cursor: pointer;
        }

        &--updated-date {
          @extend %updated-date;
          font-size: 10px;
          line-height: 15px;
          cursor: pointer;
        }

        &--worksheet-count {
          line-height: 18px;
          cursor: pointer;
        }
      }
    }
  }

  &__divider {
    opacity: 0.5;
  }
}
