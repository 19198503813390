@import 'src/sass/ve.scss';

.ve-comparison-table {

  &__columns-setter {
    margin-top: -14px;

    &__button {
      height: 30px;
      background: $maritech-background-black;
      color: $font-color-primary;
      padding: 0 $guttering-large 0 $guttering-small-medium;
      text-align: right;
      border: 1px solid $maritech-white;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      width: 100%;


      &:focus {
        border-color: $maritech-action;
      }
    }

    &__menu {
      border: 1px solid $maritech-action;
      position: absolute;
      padding: $guttering-medium $guttering-medium-large $guttering-large;
      z-index: 11;
      color: $font-color-primary;
      display: flex;
      flex-direction: column;
      right: 0;
      background: $maritech-background-blue;
      width: 344px;
    }

    &__menu-header {
      display: flex;
      justify-content: flex-end;
      &-button {
        text-transform: none;
        margin: 0;

        &:hover {
          background-color: transparent;
        }
      }
    }

    &__menu-content {
      height: 394px;
      overflow-x: hidden;
      overflow-y: auto;
      padding-right: $guttering-tiny;
      &::-webkit-scrollbar {
        width: $guttering-small;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $maritech-white;
        border-radius: 15px;
      }
      &::-webkit-scrollbar-track {
        margin-bottom: $guttering-tiny;

      }
    }
  }
}

.icon {
  font-style: normal;
  position: absolute;
  top: 0;
  right: 0;
}
