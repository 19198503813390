@import 'src/sass/ve.scss';

.menu-content {
  max-width: 250px !important;
  min-width: 250px !important;
  right: -35px !important;
  max-height: 820px !important;
  overflow: hidden;
}

.sorting-container {
  width: 100%;
  display: flex;

  &:hover>div {
    color: $maritech-action;
    background: $maritech-background-black;
  }

  .sorting-row {
    padding: 0 12px 0 28px;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:hover>div,
    &:hover>div>button {
      background: $maritech-background-black;
    }

    &-title {
      line-height: 2.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 130px;
      min-width: 135px;
      justify-content: flex-start;
    }

    &-icon-section {
      justify-content: space-between;
      display: flex;
      min-width: 80px;
    }

    &-icon {
      color: $maritech-white;
      font-size: 0.7em;

      &-active {
        color: $maritech-action;
        font-weight: bold;
      }
    }
  }
}
