@import 'src/sass/ve.scss';

.calculation-summary {
  .summary-section {
    margin: $guttering-medium $guttering-medium 0 $guttering-medium;
    padding-bottom: $guttering-medium;

    &--border {
      border-bottom: 1px solid $maritech-gold;
    }

    &--expand {
      height: 20px;
    }
  }

  .carbon-section {
    &--title {
      margin-top: 16px;
      margin-right: 4px;
    }

    &--divider {
      height: 20px;
      margin-left: 7px;
      opacity: 0.5;
      color: $maritech-white;
    }

    &--link-section {
      display: inline-flex;
      margin-top: -2px;
    }

    &--link {
      display: inline-flex;
      margin-left: 5px;
      margin-top: 5px;
    }
  }

  .sensitivity-analysis__expand_collapse-header {
    margin-top: -$guttering-tiny;
    margin-bottom: $guttering-medium;
    .header {
      margin-bottom: 0;
      color: white;
      font-size: $font-size-body-1;
    }
  }

  .clarksons-body-1-bold {
    font-size: $font-size-body-1;
    font-weight: 500;
  }

  .summary-data {
    display: table;
    width: 100%;

    &__row {
      display: table-row;

      &-cell {
        display: table-cell;
        width: 50%;
        padding: $guttering-tiny;

        &-label {
          text-align: right;
        }

        &-value {
          color: $font-color-primary;

          &--warning {
            color: $maritech-warning;
          }
        }
      }

      &:first-child &-cell {
        padding-top: 0;
      }

      &:last-child &-cell {
        padding-bottom: 0;
      }
    }
  }

  .summary-vessel-cargo {
    display: flex;
    /**
     * This container needs to have equal space to its sibling.
     * using `flex-grow` instead of `flex` as the shorthand property
     * `flex` seems to also set other properties to undesireable defaults.
     */
    flex-direction: row;
    .summary-vessel-container {
      flex-grow: 1;
    }
    .summary-cargo-container {
      flex-grow: 0.8;
    }
  }

  .summary-speed-and-cons {
    display: flex;
    align-items: center;

    &__scrubber-consumption {
      margin-left: $guttering-tiny;

      &--show {
        visibility: hidden;
      }
    }
  }
}
