@import 'src/sass/ve.scss';

.worksheet-section__property-label--placeholder {
  color: #A6ADBC20;
  margin-bottom: $guttering-small;
  font-size: $font-size-caption;
}

.tanker-numeric-input--placeholder {
  @extend .calc-input;
  text-align: right;
  background-color: #D9D9D905;
  border-color: transparent;
  padding: 0;
  pointer-events: none;

}
