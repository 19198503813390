@import 'src/sass/ve.scss';

.fallback-error {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content auto min-content;
  column-gap: $guttering-small-medium;

  color: $font-color-primary;

  &__warning-icon {
    grid-column: 1;
    grid-row: 1;
  }

  &__warning-icon.has-icon::before {
    color: $maritech-warn;
  }

  &__title {
    grid-column: 2;
    grid-row: 1;
    margin: 0;
    align-self: center;
  }

  &__message {
    grid-column: 2;
    grid-row: 2;
  }

  &__action {
    grid-column: 1 / 3;
    grid-row: 3;
    justify-self: center;
    text-decoration: none;

    button {
      margin: 0;
    }
  }
}
