@import 'src/sass/ve.scss';

.table {
  display: flex;
  flex-direction: column;

  %row-common {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__header {
    @extend %row-common;

    height: $grid-row-height;
    background: $maritech-background-black;
    border-bottom: 1px solid $maritech-white;
  }

  &__row {
    @extend %row-common;
    height: $grid-row-height - 2;
    background-color: $maritech-background-blue;

    &:nth-child(even) {
      background: $maritech-background-black;
    }
  }
}
