@import 'src/sass/ve.scss';

.error-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__icon.has-icon::before {
    font-size: 87px;
    color: $maritech-warning;
  }
}
