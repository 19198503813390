@import 'src/sass/ve.scss';

$base-border-def: 1px solid $clarksons-border-darkest;

.user-autocomplete {
  position: relative;

  &__input {
    height: 30px;
    border: $base-border-def;
    padding: 0 $guttering-small;
    background: black;

    color: $font-color-selected;

    &:focus {
      border-color: $maritech-action;
    }
  }

  &__menu {
    z-index: 999;
    cursor: pointer;
    background: $maritech-background-black;

    width: 100%;
    max-height: 22em;
    overflow-y: auto;

    border: $base-border-def;

    &-item {
      color: $font-color-primary;

      border: $base-border-def;
      border-bottom-width: 1px;

      &:last-child {
        border-bottom: 0;
      }

      // We get the border from the frame on these, so reset them:
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 10px;
      //display: inline-flex;

      &>* {
        margin-right: 5px;
        margin-left: 5px;
      }

      &-photo {
        height: 32px;
        width: 32px;
      }

      &--highlighted {
        background: $maritech-row-highlight-dark;
      }
    }

    &-nothing-found-notice,
    &-error {
      padding: 1.5em;
      text-align: center;
    }
  }

  &.dropdown > button {
      padding-left: 0;
  }
}
