@import 'src/sass/ve.scss';

.canals {
  display: flex;
  flex-direction: column;

  %canal-line-common {
    background: $maritech-background-black;
    height: $grid-row-height;
    display: flex;
    align-items: center;
  }

  %canal-canal-common {
    min-width: 438px;
    font-weight: normal;
    padding-left: $guttering-small;
  }

  %canal-data-common {
    display: flex;
    align-items: center;
    width: 175px;
    padding-right: $guttering-small;
  }

  &-header {
    @extend %canal-line-common;
    border-bottom: 1px solid $maritech-white;

    &__canal {
      @extend %canal-canal-common;
    }

    &__data {
      @extend %canal-data-common;
      flex-direction: column;
      border-left: 1px solid $maritech-white;

      &--title {
        font-weight: normal;
        padding-left: $guttering-small;
        align-self: flex-start;
        width: 100%;
      }

      &--breakdown {
        display: flex;
        flex-direction: row;
        width: 100%;

        &-ballast,
        &-laden {
          padding-left: $guttering-small;
          flex: 1;
        }

        &-laden {
          border-left: 1px solid $maritech-white;
        }
      }
    }
  }

  &-row {
    @extend %canal-line-common;

    &:nth-child(even) {
      background: $maritech-background-blue;
    }

    &__canal {
      @extend %canal-canal-common;
    }

    &__data {
      @extend %canal-data-common;

      &__ballast-cost-dropdown {
        right: -200px;
      }

      &__laden-cost-dropdown {
        right: -110px;
      }
    }
  }

  $states: 'ballast' 'laden';

  @each $state in $states {
    .canals-row__numeric-input--#{$state} {
      opacity: 0.5;
      transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    .canals-row--#{$state} {
      .canals-row__numeric-input--#{$state} {
        opacity: 1;
      }
    }
  }
}

.canals-header {
  .canals-header__data--breakdown-laden,
  .canals-header__data--breakdown-ballast {
    opacity: 0.5;
    transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &--laden {
    .canals-header__data--breakdown-laden {
      opacity: 1;
    }
  }
  &--ballast {
    .canals-header__data--breakdown-ballast {
      opacity: 1;
    }
  }
}
