@import 'src/sass/ve.scss';

.map-symbol {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .has-icon::before {
    font-size: 14px;
    margin: 0;
  }

  &--load-port {
    background: $maritech-yellow;
    color: black;
  }

  &--discharge-port {
    background: $maritech-teal;
    color: white;
  }

  &--bunker-port {
    background: $maritech-grey;
    color: white;
  }

  &--via-port {
    background: $maritech-mustard;
    color: white;
  }

  &--open-location {
    background: $maritech-violet;
    color: white;
  }
}
