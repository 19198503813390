@import 'src/sass/ve.scss';


.speed-display-container {
  display: flex;
  margin-left: 646px;
  font-size: 14px;


  &-header {
    flex-direction: column;
  }

  &-title {
    margin-left: 18px;
  }

  &-table {
    display: table;
    border-spacing: 10px 0;
  }

  &--action {
    display: flex;
    position: relative;
    top: 30px;
  }

  &--divider {
    opacity: 0.5;
    margin: -5px 0;
    height: 48px;
  }
}
.speed-column{
  display: table-cell;
  flex-direction: column;
  vertical-align: top;
}
.cons-column {
  display: table-cell;
  flex-direction: column;
  vertical-align: top;
}

.speed-display-item {
  display: table-row;

  &-label {
    display: table-cell;
    vertical-align: top;
    &:first-child {
      padding-bottom: 10px;
    }
  }
  &-value {
    display: table-cell;
    max-width: 50px;
    min-width: 20px;
    text-align: right;
    color: $font-color-primary;
    &-unit{
      margin-left: -5px;
      text-align: left;
      color: $font-color-secondary;
    }
    &:first-child {
      padding-bottom: 10px;
    }

  }
}
.speed-column .speed-display-item:last-child,
.cons-column .speed-display-item:last-child {
  vertical-align: top;
}

.edit-sac {
  display: inline-flex;
  margin-top: -5px;
  text-decoration: none;
  margin-left: 10px;
  &--open-popup-icon {
    $desiredSize: 18px;
    $originalSize: 24px;
    $scaleFactor: $desiredSize / $originalSize;
    transform: scale($scaleFactor);
    line-height: 1;

  }
}
