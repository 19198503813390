@import 'src/sass/ve.scss';

.tanker-numeric-input {
  @extend .calc-input;
  text-align: right;


  &--empty-value-in-mandatory-field {
    border: 1px solid #F7CD46;
  }

  &--dry-wet-mandatory-field {
    border-color: $maritech-gold;
  }

  &--readonly {
    background: #FFFFFF26;
    color: $font-color-tertiary;
    border: 1px solid #2e3945;
    &--on-edit-mode {
      color: $font-color-primary;
    }
    &[empty-mandatory-field='true'] {
      border: 1px solid #F7CD46;
    }
  }

  &--text-mode {
    text-align: left;
  }
}

.numeric-input__container {
  @extend .calc-input__container;

  display: flex;
  position: relative;
  align-items: center;

  input {
    padding-left: var(--input-padding-left, 15px);
    padding-right: var(--input-padding-right, 20px);

    &[data-has-validation-messages='true'] {
      padding-right: var(--input-padding-right-with-error, 20px); // Adjust this value as needed
    }
  }

  .unit-label {
    position: absolute;
    line-height: normal;
    top: auto !important;
    right: 20px;

    &[data-has-edit-button='false'] {
      right: 10px;

      &[data-has-validation-messages='true'] {
        right: 20px; // Adjust this value as needed
      }
    }

    &[data-has-validation-messages='true'] {
      right: 34px; // Adjust this value as needed
    }
  }

  .invalid_status_container {
    position: absolute;
    top: 51%;
    left: auto; // Position to the right side
    right: 0;
    transform: translateY(-50%);
    align-items: center;
    justify-content: space-between;

    &--hidden {
      /* need to retain the size so that elements around this field don't jump around */
      visibility: hidden;
    }

    &--guidance {
      fill: $maritech-orange;
    }

    &--error {
      fill: $maritech-warn;
    }

    &--icon {
      $desiredSize: 14px;
      $originalSize: 24px;
      $scaleFactor: $desiredSize / $originalSize;
      transform: scale($scaleFactor);
      line-height: 1;
    }
  }

  .calc-input__validation_messages {
    top: 105%;
  }

  .clear-button {
    position: absolute;
    top: 49%;
    right: 0;
    transform: translateY(-50%);
    align-items: center;
    background: none;
    border: none;
    color: $font-color-primary;
    padding: 0;
    cursor: pointer;
    outline: none;

    &[data-has-validation-messages='true'] {
      padding-right: 14px; // Adjust this value as needed
    }

    &__icon {
      $desiredSize: 14px;
      $originalSize: 24px;
      $scaleFactor: $desiredSize / $originalSize;
      transform: scale($scaleFactor);
      line-height: 1;
    }

    &__clear {
      top: 52%;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &--unit-precedes-value {
    flex-direction: row-reverse;

    .unit-label {
      left: 5px;
      right: auto;
    }
  }

  &--unit-precedes-value-with-value {
    .unit-label {
      left: 5px;
    }
  }

  .unit-label-transformed {
    transform: var(--unit-label-transform, translateX(-50%) translateX(0));
    transition: transform 0.2s ease-in-out;
  }
}
