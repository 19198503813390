@import 'src/sass/ve.scss';

.additional-expenses-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: $guttering-medium;

  .header {
    width: 100%;
  }
}

.pre-defined-expense-property {
  display: inline-flex;
  flex-direction: column;

  &.expense-cost-type {
    min-width: 144px;
  }

  &__label {
    text-align: right;
    font-size: $font-size-caption;
    margin-bottom: $guttering-small;
    margin-top: 12px;
  }

  &--right-margin {
    margin-right: $guttering-medium;
  }

  &__small-input {
    width: 100px;
  }
}

.other-expenses {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;

  &__table-header {
    border-bottom: 1px solid $maritech-white;
    background: $maritech-background-black;
    height: $grid-row-height;
    display: flex;
    align-items: center;

    &__cell {
      padding: $guttering-small $guttering-tiny;
    }
  }

  &__table-row {
    height: $grid-row-height;
    display: flex;
    align-items: center;

    &:nth-child(odd) {
      background: $maritech-background-black;
    }
  }

  &--description {
    flex-grow: 1;
  }

  &--cost {
    text-align: right;
    width: 100px;
  }

  &--cost-type {
    width: 144px;
  }

  &--add {
    width: 32px;
    text-align: center;
    padding: 0 $guttering-tiny;

    &-btn {
      font-size: 22px;
      vertical-align: bottom;
    }
  }

  &--remove {
    width: 32px;
    text-align: center;

    &-btn {
      font-size: 22px;
      vertical-align: bottom;

      &:hover {
        color: $maritech-warn;
      }
    }
  }
}
