@import 'src/sass/ve.scss';
.ve-checkbox {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  input {
    margin-top: 0;
    margin-bottom: 0;

    @include input-checkbox(false);
    width: 18px;
    height: 18px;

    &:before {
      border-color: $maritech-border-color;
    }

    &:before,
    &:after {
      box-sizing: border-box;
      height: 18px;
      width: 18px;
      color: white;
      font-size: 18px;
    }

    &:after {
      transform: translate3d(-2px, -22px, 0);
    }
  }
}
