@import 'src/sass/ve.scss';

.category label {
  padding-left: $guttering-medium;
}

.option-divider {
  padding: 18px $guttering-tiny $guttering-small-medium 42px;
  }

.category {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-bottom: 18px;
  justify-content: space-between;
}

.subcategory {
  padding-left: 42px;
}

.subcategories-section {

  &-hidden {
    overflow: hidden;
    height: 0;
  }

  &-visible {
    height: auto;
  }
}
