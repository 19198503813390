@import 'src/sass/ve.scss';

.summary-data {
  &__row {
    display: table-row;
    height: 30px;
    &-cell {
      width: 60px;
      display: table-cell;
      vertical-align: middle;
      &--white-font-cell {
        color: #FFFFFFE5;
      }

      &--right {
        text-align: right;
        font-weight: bold;
        color: #FFFFFFF7;
      ;
      }
    }

    &-cell {
      padding: $guttering-tiny;
    }
  }
}
