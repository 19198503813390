@import 'src/sass/ve.scss';

.imo-2020-multi-vessel {
  &__section {
    display: flex;
  }

  .scrubber__default_scrubber_type_label {
    color: $maritech-orange;
  }

  .dropdown-with-label {
    position: relative;
    .dropdown {
      display: block;
    }
  }

  .dropdown .dropdown__menu-item {
    height: 30px;
  }

  &__section-field {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    max-width: calc(48% - #{$guttering-small/2});

    &--scrubber-consumption {
      max-width: 50px;
    }

    & + & {
      margin-left: $guttering-small;
    }

    label {
      display: block;
      margin-bottom: $guttering-tiny;
    }
  }

  &__scrubber-ban {
    flex-direction: column;

    &-header {
      font-weight: bold;
      margin-top: $guttering-tiny;
    }
  }
}
