@import 'src/sass/ve.scss';

.validation-error {
  display: flex;
  align-items: center;
  background-color: $maritech-background-black;
  padding-left: $guttering-small;
  font-size: $font-size-body-1;
  min-width: 24em;
  min-height: 3em;
  &__icon {
    &--invalid {
      color: $maritech-warn;
    }
    &--guidance {
      color: $maritech-orange;
    }
  }
  &--invalid {
    border: 1px solid $maritech-warn;
  }
  &--guidance {
    border: 1px solid $maritech-orange;
  }
  &__content {
    margin: $guttering-small;
    white-space: normal;
    color: $maritech-white;
  }
  &__dismiss-button {
    // keep button as right as possible
    margin-left: auto;
    margin-right: $guttering-tiny;
  }

  .icon-only-button {
    &::before,
    &::after {
      margin-top: $guttering-small;
      font-size: 18px;
    }
  }
}
