@import 'src/sass/ve.scss';

$foreground-color: $maritech-white;
$background-color: transparent;
$odd-row-background-color: transparent;
$header-background-color: $maritech-background-black !important;
$hover-color: $clarksons-action-darker-2;
$row-border-width: 0;
$border-color: transparent;
$font-family: 'Roboto', 'Helvetica', sans-serif;
$font-size: $font-size-default;
$font-weight: 500;

@import '@ag-grid-community/styles/ag-grid';

// WARNING START:
// We are overriding the styles of the ag-grid here.
// These styles MUST come after the ag-grid.scss import for it work.
// We know this is bad, but have not many options at the moment.

.ag-header {
  background-color: $maritech-background-black;
  border-bottom: 1px solid rgba($maritech-white, 0.5);
}

.ag-header-cell,
.ag-header-group-cell {
  &:focus {
    outline: none;
  }
}

.ag-cell:focus {
  outline: none;
}

.ag-row.ag-row-level-0:hover,
/* #ExtraOddEvenRowSelectorsOnBaseGridStyle - Add the `odd` and `even` more specific selectors so that it's possible to override the `odd` and `even` without having to also specify this style.*/
.ag-row.ag-row-level-0.ag-row-odd:hover,
.ag-row.ag-row-level-0.ag-row-even:hover {
  background: $maritech-row-highlight-dark;
  color: $font-color-selected;
}

.ag-row.ag-row-selected,
/* add odd & even for same reason a as #ExtraOddEvenRowSelectorsOnBaseGridStyle */
.ag-row.ag-row-odd.ag-row-selected,
.ag-row.ag-row-even.ag-row-selected {
  background: $maritech-action;
  color: $font-color-selected;
}

// WARNING END.
