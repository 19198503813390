@import 'src/sass/ve.scss';

.app-fallback-error {
  max-width: 320px;
  margin: auto;

  .fallback-error {
    &__warning-icon.has-icon::before {
      font-size: 32px;
    }

    &__title {
      font-size: $font-size-subheader;
      font-weight: $font-weight-bold;
    }

    &__message {
      font-size: $font-size-body-1;
      padding-top: $guttering-small;
    }

    &__action {
      margin-top: $guttering-large;
    }
  }
}
