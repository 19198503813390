@import 'src/sass/ve.scss';

.search-menu {
  position: relative;
  z-index: 100;

  &-hide {
    display: none;
  }

  &-content {
    position: absolute;
    right: 1px;
    border: 1px solid $maritech-white;
    background: $maritech-background-black;
    overflow-y: auto;
  }

  &-item {
    height: 30px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a,
    button {
      height: 30px;
      display: inline-block;
      vertical-align: middle;
      padding: 8px;
    }

    &:hover,
    &:focus {
      color: $icon-button-hover;
      background: $maritech-blue-dark;

      a,
      button {
        color: $icon-button-hover;
        background: $maritech-blue-dark;
      }
    }
  }

  &-open {
    color: $maritech-action;
  }

  &-link-button {
    background: none;
    border: none;
    outline: none;
    color: #a6adbc;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;

    &:hover,
    &:focus {
      color: $icon-button-hover;
    }
  }
}
