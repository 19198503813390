@import 'src/sass/ve.scss';

.summary-cost-data {
  border-collapse: collapse;

  &__cell--numeric {
    text-align: right;
  }

  &__header {
    border-bottom: 1px solid $maritech-white;

    &-cell {
      font-weight: normal;
      padding: 0 $guttering-tiny $guttering-small $guttering-tiny;
      width: 34%;

      &-expand-collapse {
        width: 2%;
      }
      &-label {
        width: 30%;
      }
    }
  }

  &__row {
    display: table-row;

    &-cell {
      display: table-cell;
      padding: $guttering-small $guttering-tiny $guttering-small $guttering-tiny;

      &-expand-collapse {
        padding: 0;
      }

      &-value {
        color: $font-color-primary;
      }

      &-label {
        padding-left: $guttering-small-medium;
      }

      .summary-cost-data__row--group-member &-value {
        color: $maritech-white;
      }
    }

    &-group-name {
      cursor: pointer;
    }

    &--group-member {
      .summary-cost-data__row-cell-label {
        padding-left: $guttering-large + $guttering-small;
      }
    }
  }

  &__footer {
    text-align: left;

    &-cell {
      font-weight: normal;
      color: $font-color-primary;
      padding: $guttering-small $guttering-tiny $guttering-small $guttering-tiny;
    }
  }
}
.bunker-quantity-inline-warning {
  border: 1px solid $maritech-caution;
  padding: $guttering-small $guttering-medium;
  display: flex;
  align-items: center;
  margin-top: $guttering-medium;
  margin-bottom: $guttering-small;

  &__message {
    margin-right: $guttering-small;
    strong {
      color: #fff;
    }
  }
}

.bunker-quantity-inline-warning-icon {
  color: $maritech-caution;
  margin-right: $guttering-small;

  &::before {
    font-size: $font-size-subheader;
  }
}
