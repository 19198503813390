@import 'src/sass/ve.scss';

.category>.ve-checkbox {

  input {
    cursor: pointer;
  }

  label {
    cursor: pointer;
    padding-left: $guttering-medium;
  }

  input:indeterminate::before {
    background-color: #2196f3;
    border-color: #2196f3;
    font-size: 14px;
    content: "";
  }
  input:indeterminate::after {
    content: "";
    width: 10px;
    border-bottom: 2px solid white;
    transform: translate(4px, -26px);
    opacity: 1;
  }
}

.subcategory>.ve-checkbox>label {
  padding-left: 0;
}

