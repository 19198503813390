@import 'src/sass/ve.scss';

.additional-incomes-header {
  margin-top: $guttering-medium;
  margin-bottom: $guttering-medium;
  color: $maritech-white;
}

.additional-incomes {
  &__table {
    thead {
      th {
        text-align: left;
        padding: 0 $guttering-tiny;
        font-size: $font-size-caption;
        font-weight: normal;
      }
      th:nth-child(2) {
        text-align: right;
      }
    }
    td {
      padding: 0;
    }
    &__icon {
      text-align: center;
    }
  }
}
