@import 'src/sass/ve.scss';

.worksheet-fallback-error {
  max-width: 550px;
  margin: auto;

  .fallback-error {
    &__warning-icon.has-icon::before {
      font-size: 48px;
    }

    &__title {
      font-size: $font-size-headline;
      font-weight: 600;
    }

    &__message {
      font-size: $font-size-body-2;
      padding-top: $guttering-small;
    }

    &__action {
      margin-top: $guttering-max;
    }
  }
}
