@import 'src/sass/ve.scss';

.voy-totals-section {
  width: 350px;
  margin: $guttering-medium $guttering-medium 0 $guttering-medium;
}

.voy-totals-label {
  display: flex;
  align-items: center;
}


.voy-totals-asterisk-label {
  display: flex;
  align-items: center;
  &__text {
    color: #FFFFFF70;
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 15px;
  }
}

.voy-totals-label-text {
  color: #FFFFFFE5;
  font-size: $font-size-subheader;
  font-weight: 600;
  border-bottom: 0;
  margin-bottom: 10px;
}

.iconTooltip {
  $desiredSize: 14px;
  $originalSize: 24px;
  $scaleFactor: $desiredSize / $originalSize;
  transform: scale($scaleFactor);
  line-height: 1;  cursor: pointer;
}
.voy-totals-data {
  display: table;
  width: 100%;
  margin-left: 10px;
  margin-bottom: $guttering-medium;
}
