@import 'src/sass/ve.scss';

.text-tooltip {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $maritech-action;
  }
}
