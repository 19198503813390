@import 'src/sass/ve.scss';

.notice-cant-save-changes-on-colleagues-work-items {
  /* Make it follow the scroll */
  position: fixed;
  /* Need to raise the z-index over the default, so we are over the siblings that come next. */
  z-index: 1;
  min-height: 52px;
}

.warning-box__header {
  font-size: 15px;
}

.warning-box__text {
  padding-top: 5px;
}
