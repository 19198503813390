$headers-border: 1px solid $maritech-white;
$colgroup-border: 0.5px solid $maritech-grey-darker;

table {
  td,
  th {
    padding: 8px 4px 8px 4px;
  }

  th {
    font-weight: $font-weight-medium;
    &.th-normal-font {
      font-weight: unset;
    }
  }

  /* Zebra colors:*/
  thead,
  tfoot {
    tr {
      &,
        /*Also apply the same background to the cells themselves, so that they are not transparent when hovering over others (e.g. when using 'sticky' to pin columns) #ApplyBackgroundToCellsForStickyColumns */
        th,
        td {
        background-color: $panel-container-dark;
      }
    }
  }
  tbody {
    tr:nth-child(odd) {
      &,
        /*Also apply the same background to the cells themselves, so that they are not transparent when hovering over others (e.g. when using 'sticky' to pin columns) #ApplyBackgroundToCellsForStickyColumns */
        th,
        td {
        background-color: $maritech-background-blue;
      }
    }
    tr:nth-child(even) {
      &,
        /*Also apply the same background to the cells themselves, so that they are not transparent when hovering over others (e.g. when using 'sticky' to pin columns) #ApplyBackgroundToCellsForStickyColumns */
        th,
        td {
        background-color: $panel-container-dark;
      }
    }
  }

  /*Borders:*/
  /* `border-spacing` removes the gaps in lines along adjacent cells https://stackoverflow.com/a/17238021 */
  border-spacing: 0;

  border-collapse: collapse;

  colgroup:not(:last-of-type) {
    border-right: $colgroup-border;
  }

  /*
    Use `extra-header-row-column` class so that the stronger vertical border go all the way to the top, including the header.
  */
  col.extra-header-row-column {
    border-left: $headers-border;
    border-right: $headers-border;
  }
  tbody > tr:first-child {
    & > * {
      border-top: $headers-border;
    }
  }
  th[scope='row']:not(:last-child) {
    border-right: $headers-border;
  }
  th[scope='row']:not(:first-child),
  thead > tr > th[scope='column']:last-child() {
    border-left: $headers-border;
  }
  tfoot {
    td,
    th {
      border-top: $headers-border;
    }
  }
}
