.type-input {
  width: 224px;
}

.cost-input {
  width: 114px;
  .unit-label {
    top: 52%;
  }
}
