@import 'src/sass/ve.scss';

.vessel-detail-multi-vessel {
  &-container {
    display: block;
  }

  &-controls {
    display: flex;

    &--left {
      display: flex;
      align-items: flex-start;
    }

    &--right {
      margin-left: auto;
      min-width: 155px;

      &-toggle {
        float: left;
        padding-top: 2px;
      }

      &-info {
        float: right;
        padding: 0;
        margin: 0;
        &-tooltip {
          flex-wrap: wrap;
        }
      }
    }
  }

  &__small-input {
    width: 90px;
  }

  &__very-small-input {
    width: 70px;
  }

  &__medium-input {
    width: 140px;
  }
}

.vessel-name {
  width: 175px;
}

.vessel-property {
  display: inline-flex;
  flex-direction: column;

  &__label {
    font-size: $font-size-caption;
    margin-bottom: $guttering-small;
    position: relative;

    .dropdown-label {
      position: absolute;
      bottom: -$guttering-small;
    }

    &--immersion {
      height: 1.15em; //value aligned with line-height from normalize.css

      .dropdown__display {
        padding-left: 0;
      }

      .dropdown__menu {
        min-width: 97px;
      }
    }
  }

  &--right-margin {
    margin-right: $guttering-medium;
  }
}
