@import 'src/sass/ve.scss';

.app-info {
  color: $maritech-white;
  text-align: center;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding-bottom: 10px;
  align-self: end;
}
